import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Switch } from "antd";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { createMatchResult } from "../../store/matchResults";
import { fetchMatch, updateMatchConfiguration } from "../../store/matches";
import { Tiebreakers } from "./components/Tiebreakers";
import { Button } from "../../components/Button";

import "./styles.scss";
import {
  candidatesDataPath,
  lotteryResultsPath,
} from "../../services/urlBuilder";

export const Configuration = () => {
  const { lotteryId, matchId } = useParams();
  const [isPlacementOptimal, setIsPlacementOptimal] = useState();
  const [isRandomTiebreaker, setIsRandomTiebreaker] = useState();
  const [redirectTo, setRedirectTo] = useState();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.matchResults);
  const { matchWord, trialWord } = useSelector(
    (state) => state.organization.details
  );
  const { candidatesName, placementsName } =
    useSelector((state) => state.lotteries?.byId?.[lotteryId]) || {};
  const {
    id: matchConfigurationId,
    isPlacementOptimal: savedIsPlacementOptimal,
    isRandomTiebreaker: savedIsRandomTiebreaker,
    tiebreakers: savedTiebreakers = [],
  } = useSelector(
    (state) => state.matches?.byId?.[matchId]?.dataSet?.matchConfiguration
  ) || {};
  const { matchings = [] } = useSelector(
    (state) => state.matches.byId?.[matchId] || {}
  );
  const matchComplete = matchings.length > 0;

  const [tiebreakers, setTiebreakers] = useState([]);
  const [tiebreakerOptions, setTiebreakerOptions] = useState([]);
  useEffect(() => {
    if (savedTiebreakers?.length > 0) {
      setTiebreakers(savedTiebreakers);
    }
  }, [savedTiebreakers]);

  const { candidateAttrs, placementAttrs } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};

  useEffect(() => {
    dispatch(fetchMatch({ lotteryId, matchId }));
  }, [lotteryId, matchId]);

  useEffect(() => {
    const tiebreakerAttrs = isPlacementOptimal
      ? placementAttrs
      : candidateAttrs;
    if (tiebreakerAttrs) {
      const nextTiebreakerOptions = tiebreakerAttrs.map((text, index) => ({
        id: text,
        index,
        text,
        order: ">",
      }));
      setTiebreakerOptions(nextTiebreakerOptions);
    }
  }, [isPlacementOptimal, placementAttrs, candidateAttrs]);

  useEffect(() => {
    if (savedIsPlacementOptimal === true || savedIsPlacementOptimal === false) {
      setIsPlacementOptimal(savedIsPlacementOptimal);
    }
  }, [savedIsPlacementOptimal]);

  useEffect(() => {
    if (savedIsRandomTiebreaker === true || savedIsRandomTiebreaker === false) {
      setIsRandomTiebreaker(savedIsRandomTiebreaker);
    }
  }, [savedIsRandomTiebreaker]);

  const handleChangeOptimality = (optimality) => {
    setIsPlacementOptimal(optimality);
    setTiebreakers([]);
  };

  const handleChangeRandomTiebreaker = (isDeterministic) => {
    setIsRandomTiebreaker(!isDeterministic);
    if (!isDeterministic) {
      setTiebreakers([]);
    }
  };

  const handleChangeTiebreakers = (selectedValues, allValues) => {
    setTiebreakers(selectedValues);
    if (allValues) {
      setTiebreakerOptions(allValues);
    }
  };

  const handleRunMatch = () => {
    dispatch(
      updateMatchConfiguration({
        lotteryId,
        matchId,
        matchConfigurationId,
        data: {
          isPlacementOptimal: isPlacementOptimal,
          isRandomTiebreaker: isRandomTiebreaker,
          tiebreakers,
        },
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(createMatchResult({ lotteryId, matchId }))
          .then(unwrapResult)
          .then((response) => {
            dispatch(fetchMatch({ lotteryId, matchId }))
              .then(unwrapResult)
              .then(() => {
                setRedirectTo(lotteryResultsPath(lotteryId, matchId));
              });
          });
      });
  };

  return (
    <div className="configuration-page">
      <div className="configuration-wrapper">
        <h6>
          <FormattedMessage
            id="CONFIGURATION_HEADER"
            values={{ name: matchWord }}
          />
        </h6>

        <div className="section">
          <div className="toggle-row">
            <div
              className={classNames("toggle-text cm", {
                selected: !isPlacementOptimal,
              })}
            >
              {candidatesName}
            </div>
            <Switch
              checked={isPlacementOptimal}
              disabled={matchComplete}
              onChange={handleChangeOptimality}
            />
            <div
              className={classNames("toggle-text", {
                selected: isPlacementOptimal,
              })}
            >
              {placementsName}
            </div>
          </div>
        </div>

        <div className="section">
          <div className="toggle-row">
            <div
              className={classNames("toggle-text", {
                selected: isRandomTiebreaker,
              })}
            >
              Randomized
            </div>
            <Switch
              checked={!isRandomTiebreaker}
              disabled={matchComplete}
              onChange={handleChangeRandomTiebreaker}
            />
            <div
              className={classNames("toggle-text", {
                selected: !isRandomTiebreaker,
              })}
            >
              Prioritized
            </div>
          </div>

          <DndProvider backend={HTML5Backend}>
            <Tiebreakers
              values={tiebreakerOptions}
              selected={tiebreakers}
              onChange={handleChangeTiebreakers}
              disabled={isRandomTiebreaker || matchComplete}
            />
          </DndProvider>
        </div>

        <Button
          className="configuration-submit"
          type="primary"
          onClick={handleRunMatch}
          loading={loading}
          disabled={loading || matchComplete}
        >
          <FormattedMessage id="RUN_MATCH" values={{ name: trialWord }} />
        </Button>

        {redirectTo && <Redirect to={redirectTo} />}
      </div>
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const defaultMemberState = {
  headers: [],
  rows: [],
  attributeMapping: {},
};

const dataUploadSlice = createSlice({
  name: "dataUpload",
  initialState: {
    candidates: defaultMemberState,
    placements: defaultMemberState,
    preferences: defaultMemberState,
  },
  reducers: {
    setCandidateData(state, action) {
      const {
        payload: { headers, rows },
      } = action;
      state.candidates.headers = headers;
      state.candidates.rows = rows;
    },
    setCandidateMapping(state, action) {
      const { payload } = action;
      state.candidates.attributeMapping = payload;
    },
    setPlacementData(state, action) {
      const {
        payload: { headers, rows },
      } = action;
      state.placements.headers = headers;
      state.placements.rows = rows;
    },
    setPlacementMapping(state, action) {
      const { payload } = action;
      state.placements.attributeMapping = payload;
    },
    setPreferenceData(state, action) {
      const {
        payload: { headers, rows },
      } = action;
      state.preferences.headers = headers;
      state.preferences.rows = rows;
    },
    setPreferenceMapping(state, action) {
      const { payload } = action;
      state.preferences.attributeMapping = payload;
    },
  },
});

const { actions, reducer } = dataUploadSlice;

export const {
  setCandidateData,
  setPlacementData,
  setPreferenceData,
  setCandidateMapping,
  setPlacementMapping,
  setPreferenceMapping,
} = actions;

export default reducer;

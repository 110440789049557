import XLSX from "xlsx";

export const spreadsheetParser = (dataParam) => {
  let workbook;
  let rows;
  let headers = [];

  const data = new Uint8Array(dataParam);
  workbook = XLSX.read(data, { type: "array" });

  const { Sheets = {}, SheetNames: [firstSheetName] = [] } = workbook;
  const sheet = Sheets[firstSheetName];

  rows = XLSX.utils.sheet_to_json(sheet);

  const range = XLSX.utils.decode_range(sheet["!ref"]);
  const R = range.s.r; /* start in the first row */

  /* walk every column in the range */
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cell =
      sheet[
        XLSX.utils.encode_cell({ c: C, r: R })
      ]; /* find the cell in the first row */

    if (cell && cell.t) {
      const hdr = XLSX.utils.format_cell(cell);
      headers.push(hdr);
    }
  }

  return { headers, rows };
};

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Radio } from "antd";

import "./styles.scss";

export const TabNav = ({ buttonClassName, selectedPath, values }) => {
  return (
    <div className="tab-nav">
      <Radio.Group
        className="tab-nav-group"
        buttonStyle="solid"
        value={selectedPath}
      >
        {values &&
          values.map((value) => (
            <Link key={value.path} to={value.path}>
              <Radio.Button
                className={`tab-nav-button ${buttonClassName}`}
                value={value.path}
              >
                {value.content}
              </Radio.Button>
            </Link>
          ))}
      </Radio.Group>
    </div>
  );
};

TabNav.propTypes = {
  buttonClassName: PropTypes.string,
  selectedPath: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      content: PropTypes.node,
    })
  ).isRequired,
};

TabNav.defaultProps = {
  buttonClassName: "",
  selectedPath: "",
};

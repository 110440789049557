import { configureStore } from "@reduxjs/toolkit";

import user from "./user";
import organization from "./organization";
import lotteries from "./lotteries";
import matches from "./matches";
import candidates from "./candidates";
import placements from "./placements";
import preferences from "./preferences";
import mutualPreferences from "./mutualPreferences";
import matchResults from "./matchResults";
import dataUpload from "./dataUpload";

export default configureStore({
  reducer: {
    user,
    organization,
    lotteries,
    matches,
    candidates,
    placements,
    preferences,
    mutualPreferences,
    matchResults,
    dataUpload,
  },
});

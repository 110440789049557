import React from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Menu } from "antd";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { CSSTransitionGroup } from "react-transition-group";
import { DownOutlined } from "@ant-design/icons";

import "./styles.scss";

export const TabNavDropdown = ({ icon, values }) => {
  const { pathname, search } = useLocation();

  const pathIsCurrent = (path) => path === `${pathname}${search}`;
  const currentValue = values.find(({ path }) => pathIsCurrent(path)) || {};
  const restValues = values.filter(({ path }) => !pathIsCurrent(path));

  const menu = (
    <Menu className="tab-nav-menu">
      {restValues.map(({ path, text }) => (
        <Menu.Item key={path} className="tab-nav-menu-item">
          <Link to={path}>{text}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="tab-nav-dropdown">
      <CSSTransitionGroup
        transitionName="dropdown-button"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
          <Button className="tab-nav-button">
            {icon}
            {currentValue.text}
            <DownOutlined />
          </Button>
        </Dropdown>
      </CSSTransitionGroup>
    </div>
  );
};

TabNavDropdown.propTypes = {
  icon: PropTypes.node,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

TabNavDropdown.defaultProps = {
  icon: "",
  values: [],
};

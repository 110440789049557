import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../services/api";

export const fetchMutualPreferences = createAsyncThunk(
  "mutual_preferences/fetch",
  async (data, thunkAPI) => {
    const response = await api.fetchMutualPreferences(data);
    return response.data;
  }
);

export const updateMutualPreference = createAsyncThunk(
  "mutual_preferences/update",
  async (data, thunkAPI) => {
    const response = await api.updateMutualPreference(data);
    return response.data;
  }
);

const mutualPreferencesSlice = createSlice({
  name: "mutual_preferences",
  initialState: {
    loading: false,
    byId: {},
  },
  reducers: {},
  extraReducers: {
    [fetchMutualPreferences.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchMutualPreferences.fulfilled]: (state, action) => {
      const mutualPreferences = action.payload || [];

      state.loading = false;
      state.byId = mutualPreferences.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
    },
    [updateMutualPreference.pending]: (state, action) => {
      const {
        meta: {
          arg: {
            id,
            data: { details },
          },
        },
      } = action;
      state.byId[id].details = details;
    },
    [updateMutualPreference.fulfilled]: (state, action) => {
      const { payload } = action;

      state.byId[payload.id] = payload;
    },
  },
});

const { reducer } = mutualPreferencesSlice;

export default reducer;

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { FileDrop } from "react-file-drop";
import { FormattedMessage } from "react-intl";
import { Row, Col, Button, Form } from "antd";

import { spreadsheetParser } from "../../../../services/spreadsheetParser";

import "./styles.scss";

export const FileUpload = ({ onUpload }) => {
  const inputRef = useRef();

  const handleFile = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const { headers, rows } = spreadsheetParser(e.target.result);
      onUpload(headers, rows);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleTargetClick = () => {
    inputRef.current.click();
  };

  const handleDrop = (files) => {
    const [file] = files;
    handleFile(file);
  };

  const handleFinderUpload = (e) => {
    const {
      target: {
        files: [file],
      },
    } = e;
    handleFile(file);
  };

  return (
    <Row>
      <Col xs={20} offset={2}>
        <Form className="spreadsheet-import">
          <FileDrop
            className="avela-file-drop"
            onTargetClick={handleTargetClick}
            onDrop={handleDrop}
          >
            <div className="drop-files-text">
              <FormattedMessage id="DROP_FILES" />
            </div>
            <Button className="file-browse-button">
              <FormattedMessage id="BROWSE" />
            </Button>
          </FileDrop>
          <input
            ref={inputRef}
            type="file"
            accept=".xls, .xlsx, .csv"
            onChange={handleFinderUpload}
            style={{ visibility: "hidden" }}
          />
        </Form>
      </Col>
    </Row>
  );
};

FileUpload.propTypes = {
  onUpload: PropTypes.func.isRequired,
};

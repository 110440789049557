import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../services/api";

export const fetchOrganization = createAsyncThunk(
  "organization/fetch",
  async (data, thunkAPI) => {
    const response = await api.fetchOrganizations(data);
    return response.data;
  }
);

export const createOrganization = createAsyncThunk(
  "organization/create",
  async (data, thunkAPI) => {
    const response = await api.createOrganization(data);
    return response.data;
  }
);

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    details: {},
  },
  reducers: {},
  extraReducers: {
    [fetchOrganization.fulfilled]: (state, action) => {
      state.details = action.payload;
    },
  },
});

const { reducer } = organizationSlice;

export default reducer;

import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  candidatesDataPath,
  placementsDataPath,
  preferencesDataPath,
} from "../../../../services/urlBuilder";
import { TabNav } from "../../../../components/TabNav";

import "./styles.scss";
import { useSelector } from "react-redux";

export const DataNav = () => {
  const { pathname } = useLocation();
  const { lotteryId, matchId } = useParams();
  const { candidatesName, placementsName } =
    useSelector((state) => state.lotteries?.byId?.[lotteryId]) || {};

  const selectedPath = useMemo(() => {
    return [
      candidatesDataPath(lotteryId, matchId),
      placementsDataPath(lotteryId, matchId),
      preferencesDataPath(lotteryId, matchId),
    ].find((path) => pathname.includes(path));
  }, [lotteryId, matchId, pathname]);

  return (
    <div className="data-nav">
      <TabNav
        buttonClassName="data-nav-button"
        selectedPath={selectedPath}
        values={[
          {
            path: candidatesDataPath(lotteryId, matchId),
            content: candidatesName,
          },
          {
            path: placementsDataPath(lotteryId, matchId),
            content: placementsName,
          },
          {
            path: preferencesDataPath(lotteryId, matchId),
            content: "Preferences",
          },
        ]}
      />
    </div>
  );
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { uniq } from "lodash";
import { useIntl } from "react-intl";
import { unwrapResult } from "@reduxjs/toolkit";

import { MapColumns } from "../../components";
import { CANDIDATE_IDENTIFIER } from "../../constants";
import { useMappingOptions } from "../../hooks/useMappingOptions";
import { createCandidates } from "../../../../store/candidates";
import { setCandidateMapping } from "../../../../store/dataUpload";
import { useQuery } from "../../../../hooks/useQuery";
import { candidatesDataPath } from "../../../../services/urlBuilder";

export const CandidatesMapData = () => {
  const { lotteryId, matchId } = useParams();
  const [redirectTo, setRedirectTo] = useState();
  const query = useQuery();
  const isUpload = query.get("upload") === "true";
  const dispatch = useDispatch();
  const intl = useIntl();
  const { headers: uploadHeaders, rows: uploadRows } = useSelector(
    (state) => state.dataUpload.candidates
  );
  const { candidateAttrs: savedHeaders, candidates: savedRows } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};
  const { creating } = useSelector((state) => state.candidates);
  const { candidateMappingOptions } = useMappingOptions();

  const candidatesAttributeMapping = useSelector((state) => {
    return state.dataUpload?.candidates?.attributeMapping;
  });

  const handleSubmitCandidates = (data) => {
    if (isUpload) {
      dispatch(
        createCandidates({
          ...data,
          rows: uploadRows,
          headers: uploadHeaders,
        })
      )
        .then(unwrapResult)
        .then(() => {
          setRedirectTo(candidatesDataPath(lotteryId, matchId));
        });
    }
  };

  const handleChangeCandidateMapping = (attributeMapping) => {
    dispatch(setCandidateMapping(attributeMapping));
  };

  const getCustomValidator = (option) => {
    return () => ({
      validator(_, value) {
        if (option.value === CANDIDATE_IDENTIFIER) {
          const selectedColumnValues = uploadRows.map((row) => row[value]);
          if (
            selectedColumnValues.length === uniq(selectedColumnValues).length
          ) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(intl.formatMessage({ id: "ERROR_COLUMN_UNIQUENESS" }))
          );
        }

        return Promise.resolve();
      },
    });
  };

  return (
    <>
      <MapColumns
        options={candidateMappingOptions}
        attributeMapping={candidatesAttributeMapping}
        onSubmit={handleSubmitCandidates}
        submitting={creating}
        onChangeMapping={handleChangeCandidateMapping}
        headers={isUpload ? uploadHeaders : savedHeaders}
        exampleRow={uploadRows[0]}
        customValidator={getCustomValidator}
      />
      {redirectTo && <Redirect to={redirectTo} />}
    </>
  );
};

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

import { GRAPH_COLORS } from "../../../../constants/colors";

import "./styles.scss";

export const ResultsPieChart = ({ attribute }) => {
  const { matchId } = useParams();
  const { pairs = [] } =
    useSelector((state) => state.matchResults.byMatchId[matchId]) || {};

  const data = useMemo(() => {
    if (attribute) {
      const countByValue = {};

      const [firstPair] = pairs;
      const isPlacement = !!firstPair?.placement?.details?.[attribute];

      pairs.forEach((pair) => {
        const value = isPlacement
          ? pair.placement.details[attribute]
          : pair.candidate.details[attribute];
        countByValue[value] = !!countByValue[value]
          ? countByValue[value] + 1
          : 1;
      });

      return Object.keys(countByValue).map((key) => ({
        name: key,
        count: countByValue[key],
      }));
    } else {
      return [];
    }
  }, [pairs, attribute]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="results-pie-chart">
      <ResponsiveContainer
        className="responsive-container"
        width="100%"
        height={250}
      >
        <PieChart height={200}>
          <Pie
            data={data}
            dataKey="count"
            nameKey="name"
            outerRadius={100}
            innerRadius={40}
            label={renderCustomizedLabel}
            labelLine={false}
            cy="45%"
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={GRAPH_COLORS[index]} />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </ResponsiveContainer>

      <div className="breakdown-by-category">
        {data.map((datum) => (
          <div className="category">
            {datum.name}: {datum.count}
          </div>
        ))}
      </div>
    </div>
  );
};

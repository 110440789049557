import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Input, Menu, Modal } from "antd";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { Button } from "../../components/Button";
import { lotteryPath } from "../../services/urlBuilder";
import "./styles.scss";
import {
  fetchLotteries,
  fetchLottery,
  updateLottery,
  deleteLottery,
} from "../../store/lotteries";

const { confirm } = Modal;

export const LotteryTile = ({ id, name, date, finalized }) => {
  const [editing, setEditing] = useState(false);
  const [nextName, setNextName] = useState(name);
  const { id: organizationId, matchWord } = useSelector(
    (state) => state.organization.details
  );
  const dispatch = useDispatch();
  const intl = useIntl();

  const formattedDate = useMemo(() => {
    const d = new Date(date);
    return d.toLocaleDateString();
  }, [date]);

  const handleRename = () => {
    setEditing(true);
    setNextName(name);
  };

  const handleDelete = () => {
    confirm({
      title: intl.formatMessage({ id: "CONFIRM_DELETE" }, { name: matchWord }),
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        dispatch(deleteLottery({ lotteryId: id })).then(() => {
          dispatch(fetchLotteries({ organizationId }));
        });
      },
    });
  };

  const handleChangeName = ({ target: { value } }) => {
    setNextName(value);
  };
  const handleSaveName = () => {
    dispatch(updateLottery({ lotteryId: id, name: nextName })).then(() => {
      dispatch(fetchLottery({ lotteryId: id })).then(() => {
        setEditing(false);
      });
    });
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item onClick={handleRename}>Rename</Menu.Item>
      <Menu.Item onClick={handleDelete}>Delete</Menu.Item>
    </Menu>
  );

  return (
    <div className="lottery-tile">
      <div className="lottery-menu">
        <Dropdown overlay={dropdownMenu} placement="bottomRight">
          <DownOutlined style={{ fontSize: "12px" }} />
        </Dropdown>
      </div>

      <div className="lottery-name">
        {editing ? (
          <Input
            value={nextName}
            onChange={handleChangeName}
            onBlur={handleSaveName}
            onPressEnter={handleSaveName}
          />
        ) : (
          name
        )}
      </div>

      <div className="lottery-date note-text">
        <FormattedMessage
          id={finalized ? "FINALIZED_ON" : "EDITED_ON"}
          values={{ date: formattedDate }}
        />
      </div>

      <div
        className="lottery-status"
        style={{ visibility: finalized && "hidden" }}
      >
        <FormattedMessage id="IN_PROGRESS" />
      </div>

      <Link to={lotteryPath(id)}>
        <Button
          className="lottery-button"
          type={finalized ? "outlined" : "primary"}
        >
          <FormattedMessage id={finalized ? "VIEW_RESULTS" : "RESUME"} />
        </Button>
      </Link>
    </div>
  );
};

LotteryTile.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  finalized: PropTypes.bool,
};

LotteryTile.defaultProps = {
  finalized: false,
};

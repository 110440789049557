import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Form, Select, Row, Col } from "antd";

import { Button } from "../../../../components/Button";
import "./styles.scss";

export const MapColumns = (props) => {
  const {
    attributeMapping,
    headers,
    exampleRow,
    options,
    onChangeMapping,
    onSubmit,
    submitting,
    customValidator,
  } = props;

  const { lotteryId, matchId } = useParams();
  const intl = useIntl();

  const handleChangeMapping = (attribute) => (header) => {
    const nextAttributeMapping = { ...attributeMapping };

    nextAttributeMapping[attribute] = header;

    onChangeMapping(nextAttributeMapping);
  };

  const handleSubmit = () => {
    onSubmit({ lotteryId, matchId, attributeMapping });
  };

  const getCustomRuleForOption = (option) => {
    if (customValidator) {
      return customValidator(option);
    }
  };

  return (
    <Form
      className="map-columns"
      onFinish={handleSubmit}
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      labelAlign="left"
      colon={false}
    >
      <h5>Map your columns to Avela Properties</h5>
      <div className="mapping-section">
        <Row>
          <Col xs={8} className="mapping-header">
            {intl.formatMessage({ id: "AVELA_PROPERTY" })}
          </Col>
          <Col xs={16} className="mapping-header">
            {intl.formatMessage({ id: "COLUMN_HEADER_DESCRIPTION" })}
          </Col>
        </Row>
        <div className="mapping-rows-container">
          {options.map((option, index) => (
            <Row key={index} className="mapping-row">
              <Form.Item
                className="mapping-form-item"
                name={option.value}
                label={option.text}
                rules={[
                  {
                    required: option.required,
                    message: "This field is required",
                  },
                  getCustomRuleForOption(option),
                ]}
              >
                <Select
                  className="mapping-select"
                  dropdownClassName="data-mapping-select-dropdown"
                  onChange={handleChangeMapping(option.value)}
                >
                  <Select.Option className="mapping-select-option" value="" />
                  {headers.map((header, index) => (
                    <Select.Option
                      key={index}
                      value={header}
                      className="mapping-select-option"
                    >
                      <Row>
                        <Col xs={8}>{header}</Col>
                        <Col xs={16} className="option-example">
                          E.g. {exampleRow[header]}
                        </Col>
                      </Row>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>
          ))}
        </div>
      </div>

      <Button
        className="map-columns-submit"
        type="primary"
        htmlType="submit"
        loading={submitting}
        disabled={submitting}
      >
        Upload
      </Button>
    </Form>
  );
};

MapColumns.propTypes = {
  attributeMapping: PropTypes.shape(),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    })
  ),
  headers: PropTypes.arrayOf(PropTypes.string),
  exampleRow: PropTypes.shape(),
  onChangeMapping: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  customValidator: PropTypes.func,
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../services/api";
import { updatePlacement } from "../placements";

export const fetchMatches = createAsyncThunk(
  "matches/fetch",
  async (data, thunkAPI) => {
    const response = await api.fetchMatches(data);
    return response.data;
  }
);

export const fetchMatch = createAsyncThunk(
  "matches/get",
  async (data, thunkAPI) => {
    const response = await api.fetchMatch(data);
    return response.data;
  }
);

export const createMatch = createAsyncThunk(
  "matches/create",
  async (data, thunkAPI) => {
    const response = await api.createMatch(data);
    return response.data;
  }
);

export const updateMatch = createAsyncThunk(
  "matches/update",
  async (data, thunkAPI) => {
    const response = await api.updateMatch(data);
    return response.data;
  }
);

export const duplicateMatch = createAsyncThunk(
  "matches/duplicate",
  async (data, thunkAPI) => {
    const response = await api.duplicateMatch(data);
    return response.data;
  }
);

export const updateMatchConfiguration = createAsyncThunk(
  "matches/updateConfig",
  async (data, thunkAPI) => {
    const response = await api.updateMatchConfiguration(data);
    return response.data;
  }
);

export const deleteMatch = createAsyncThunk(
  "matches/delete",
  async (data, thunkAPI) => {
    const response = await api.deleteMatch(data);
    return response.data;
  }
);

const matchesSlice = createSlice({
  name: "matches",
  initialState: {
    byId: {},
  },
  reducers: {},
  extraReducers: {
    [fetchMatches.pending]: (state, action) => {
      state.byId = {};
    },
    [fetchMatches.fulfilled]: (state, action) => {
      action.payload.forEach((match) => {
        state.byId[match.id] = {
          ...state.byId[match.id],
          ...match,
        };
      });
    },
    [fetchMatch.pending]: (state, action) => {
      const {
        meta: {
          arg: { matchId },
        },
      } = action;
      state.byId[matchId] = {
        ...state.byId[matchId],
        loading: true,
      };
    },
    [fetchMatch.fulfilled]: (state, action) => {
      const {
        payload,
        payload: { id },
      } = action;
      state.byId[id] = {
        ...state.byId[id],
        ...payload,
        loading: false,
      };
    },
    [createMatch.fulfilled]: (state, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    [updateMatch.fulfilled]: (state, action) => {
      state.byId[action.payload.id] = {
        ...state.byId[action.payload.id],
        ...action.payload,
        dataSet: {
          ...state.byId[action.payload.id]?.dataSet,
          ...action.payload.dataSet,
        },
      };
    },
    [duplicateMatch.fulfilled]: (state, action) => {
      state.byId[action.payload.id] = {
        ...state.byId[action.payload.id],
        ...action.payload,
      };
    },
    [deleteMatch.fulfilled]: (state, action) => {
      const {
        meta: {
          arg: { matchId },
        },
      } = action;
      delete state.byId[matchId];
    },
    [updatePlacement.pending]: (state, action) => {
      const {
        meta: {
          arg: { matchId, placementId, details },
        },
      } = action;
      const placement = state.byId[matchId].dataSet.placements.find(
        (p) => p.id === placementId
      );
      placement.details = details;
    },
  },
});

const { reducer } = matchesSlice;

export default reducer;

import Cookies from "js-cookie";

class Auth {
  setTokens({ access_token, id_token } = {}) {
    Cookies.set("avela_access_token", access_token || "");
    Cookies.set("avela_id_token", id_token || "");
  }

  resetTokens() {
    this.setTokens();
  }

  get accessToken() {
    return Cookies.get("avela_access_token");
  }

  get idToken() {
    return Cookies.get("avela_id_token");
  }

  isLoggedIn() {
    return !!this.accessToken;
  }

  login() {
    window.location.href =
      `${process.env.REACT_APP_COGNITO_URL}/login` +
      `?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}` +
      "&response_type=token" +
      "&scope=email+openid" +
      `&redirect_uri=${window.location.origin}/auth/callback`;
  }

  logout() {
    this.resetTokens();
  }
}

const instance = new Auth();
export { instance as Auth };

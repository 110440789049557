import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import {
  candidatesDataPath,
  candidatesDataPathDef,
  candidatesImportDataPathDef,
  candidatesMapDataPathDef,
  placementsDataPathDef,
  placementsImportDataPathDef,
  placementsMapDataPathDef,
  preferencesDataPathDef,
  preferencesImportDataPathDef,
  preferencesMapDataPathDef,
  lotteryDataPathDef,
} from "../../services/urlBuilder";

import { Candidates } from "./scenes/Candidates";
import { Placements } from "./scenes/Placements";
import { Preferences } from "./scenes/Preferences";
import { CandidatesDataImport } from "./scenes/CandidatesDataImport";
import { PlacementsDataImport } from "./scenes/PlacementsDataImport";
import { PreferencesDataImport } from "./scenes/PreferencesDataImport";
import { CandidatesMapData } from "./scenes/CandidatesMapData";
import { PlacementsMapData } from "./scenes/PlacementsMapData";
import { PreferencesMapData } from "./scenes/PreferencesMapData";
import { DataNav } from "./components/DataNav";

const DataPage = () => {
  const { lotteryId, matchId } = useParams();
  const { path: matchPath } = useRouteMatch();

  return (
    <>
      <DataNav />

      <Switch>
        <Route exact path={lotteryDataPathDef()}>
          <Redirect to={candidatesDataPath(lotteryId, matchId)} />
        </Route>

        <Route exact path={candidatesDataPathDef()}>
          <Candidates />
        </Route>

        <Route path={candidatesImportDataPathDef()}>
          <CandidatesDataImport />
        </Route>

        <Route path={candidatesMapDataPathDef()}>
          <CandidatesMapData />
        </Route>

        <Route exact path={placementsDataPathDef()}>
          <Placements />
        </Route>

        <Route path={placementsImportDataPathDef()}>
          <PlacementsDataImport />
        </Route>

        <Route path={placementsMapDataPathDef()}>
          <PlacementsMapData />
        </Route>

        <Route exact path={preferencesDataPathDef()}>
          <Preferences />
        </Route>

        <Route path={preferencesImportDataPathDef()}>
          <PreferencesDataImport />
        </Route>

        <Route path={preferencesMapDataPathDef()}>
          <PreferencesMapData />
        </Route>
      </Switch>
    </>
  );
};

export default DataPage;

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./styles.scss";

export const Loading = () => {
  return (
    <div className="loading-component">
      <LoadingOutlined spin />
    </div>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../services/api";
import { fetchMatch, deleteMatch } from "../matches";

export const createMatchResult = createAsyncThunk(
  "matchResults/createMatchResult",
  async (data, thunkAPI) => {
    const response = await api.createMatchResult(data);
    return response.data;
  }
);

const matchResultsSlice = createSlice({
  name: "matchResults",
  initialState: {
    loading: false,
    byMatchId: {},
  },
  reducers: {},
  extraReducers: {
    [createMatchResult.pending]: (state, action) => {
      state.loading = true;
    },
    [createMatchResult.fulfilled]: (state, action) => {
      state.loading = false;
      state.byMatchId[action.meta.arg.matchId] = {
        id: action.meta.arg.matchId,
        pairs: action.payload,
      };
    },
    [fetchMatch.fulfilled]: (state, action) => {
      state.byMatchId[action.payload.id] = {
        id: action.payload.id,
        pairs: action.payload.matchings,
      };
    },
    [deleteMatch.fulfilled]: (state, action) => {
      const {
        meta: {
          arg: { matchId },
        },
      } = action;
      delete state.byMatchId[matchId];
    },
  },
});

const { reducer } = matchResultsSlice;

export default reducer;

export const BRAND_PRIMARY_1 = "#5D1CA8";
export const BRAND_PRIMARY_2 = "#7B41D8";
export const BRAND_PRIMARY_3 = "#477DD2";

export const SECONDARY_1 = "#055ADF";
export const SECONDARY_2 = "#5396FF";
export const SECONDARY_3 = "#20BCEE";

export const GRAPH_1 = SECONDARY_1;
export const GRAPH_2 = SECONDARY_2;
export const GRAPH_3 = SECONDARY_3;
export const GRAPH_4 = "#E7846E";
export const GRAPH_5 = "#EDD56A";
export const GRAPH_6 = "#E8E19A";
export const GRAPH_7 = "#3AAF77";
export const GRAPH_8 = "#C873D0";

export const GRAPH_COLORS = [
  GRAPH_1,
  GRAPH_2,
  GRAPH_3,
  GRAPH_4,
  GRAPH_5,
  GRAPH_6,
  GRAPH_7,
  GRAPH_8,
];

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";

import { LotteryTile } from "../../components/LotteryTile";
import { fetchLotteries } from "../../store/lotteries";
import { NewLotteryTile } from "../../components/NewLotteryTile";

import "./styles.scss";

export const Lotteries = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => state.organization.details.id);
  const lotteriesById = useSelector((state) => state.lotteries.byId);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchLotteries({ organizationId }));
    }
  }, [dispatch, organizationId]);

  return (
    <div className="lottery-history">
      <Row className="lottery-history-row">
        <Col xs={24} md={12} lg={8} xxl={6}>
          <NewLotteryTile />
        </Col>
        {Object.keys(lotteriesById).map((lotteryId) => {
          const { name, updatedAt, isFinalResult } = lotteriesById[lotteryId];
          return (
            <Col key={lotteryId} xs={24} md={12} lg={8} xxl={6}>
              <LotteryTile
                id={lotteryId}
                name={name}
                date={updatedAt}
                finalized={isFinalResult}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

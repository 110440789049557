import { useMemo } from "react";
import { useSelector } from "react-redux";
import { difference, intersection } from "lodash";

export const useMatchedData = (matchId) => {
  const { pairs } =
    useSelector((state) => state.matchResults.byMatchId[matchId]) || {};
  const { candidates = [], placements = [] } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};
  const candidateIds = candidates.map((c) => c.id);
  const placementIds = placements.map((p) => p.id);

  const { matched, matchedCandidateIds } = useMemo(() => {
    const matched = [];
    const matchedCandidateIds = [];

    pairs &&
      pairs.forEach((pair) => {
        const {
          details: candidateDetails,
          uniqueIdentifierAttr: candidateIdAttr,
        } = pair.candidate;
        const {
          details: placementDetails,
          uniqueIdentifierAttr: placementIdAttr,
          capacityAttr: placementCapacityAttr,
        } = pair.placement;

        const candidateKeys = Object.keys(candidateDetails);
        const placementKeys = Object.keys(placementDetails);
        const conflictingKeys = intersection(
          candidateKeys,
          placementKeys
        ).reduce((acc, cur) => {
          acc[cur] = cur;
          return acc;
        }, {});

        const { [candidateIdAttr]: candidateId, ...restCandidate } =
          candidateDetails;
        const {
          [placementIdAttr]: placementId,
          [placementCapacityAttr]: placementCapacity,
          ...restPlacement
        } = placementDetails;

        const resolvedPlacementDetails = Object.keys(restPlacement).reduce(
          (acc, key) => {
            if (conflictingKeys[key]) {
              acc[`Placement ${key}`] = placementDetails[key];
            } else {
              acc[key] = placementDetails[key];
            }

            return acc;
          },
          {}
        );

        matchedCandidateIds.push(pair.candidate.id);

        matched.push({
          ...pair.mutualRating?.details,
          ...restCandidate,
          ...resolvedPlacementDetails,
        });
      });

    return { matched, matchedCandidateIds };
  }, [pairs]);

  const unmatchedCandidates = useMemo(() => {
    const unmatchedCandidateIds = difference(candidateIds, matchedCandidateIds);
    return unmatchedCandidateIds.map((id) => {
      const candidate = candidates.find((c) => c.id === id);
      return {
        [candidate.uniqueIdentifierAttr]: candidate.uniqueIdentifier,
        ...candidate.details,
      };
    });
  }, [candidateIds, matchedCandidateIds]);

  const unmatchedPlacements = useMemo(() => {
    const result = [];

    placementIds.forEach((id) => {
      const placement = placements.find((p) => p.id === id);
      const parsedCapacity = Number.parseInt(
        placement.details[placement.capacityAttr]
      );
      const capacity = isNaN(parsedCapacity) ? 1 : parsedCapacity;

      const numMatched = pairs.reduce((acc, cur) => {
        return cur.placement.id === id ? acc + 1 : acc;
      }, 0);

      for (let i = 0; i < capacity - numMatched; i++) {
        const { [placement.capacityAttr]: capacity, ...restDetails } =
          placement.details;
        result.push({
          [placement.uniqueIdentifierAttr]: placement.uniqueIdentifier,
          ...restDetails,
        });
      }
    });

    return result;
  }, [placementIds]);

  return { matched, unmatchedCandidates, unmatchedPlacements };
};

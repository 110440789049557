import React from "react";
import { Button as AntdButton } from "antd";
import classNames from "classnames";

import "./styles.scss";

export const Button = (props) => {
  const { className, ...restProps } = props;
  return (
    <AntdButton
      className={classNames("avela-button", className)}
      {...restProps}
    />
  );
};

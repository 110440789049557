export const shallowCamelCase = (data) => {
  return Object.keys(data).reduce((acc, datumKey) => {
    const camelCaseKey = datumKey.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace("-", "").replace("_", "");
    });

    acc[camelCaseKey] = data[datumKey];
    return acc;
  }, {});
};

export const shallowCamelCaseArray = (data) => {
  return data.map((datum) => shallowCamelCase(datum));
};

export const shallowSnakeCase = (data) => {
  return Object.keys(data).reduce((acc, datumKey) => {
    const snakeCaseKey = datumKey.replace(
      /[A-Z]/g,
      (letter) => `_${letter.toLowerCase()}`
    );
    acc[snakeCaseKey] = data[datumKey];
    return acc;
  }, {});
};

export const shallowSnakeCaseArray = (data) => {
  return data.map((datum) => shallowSnakeCase(datum));
};

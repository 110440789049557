import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { createOrganization } from "../../store/organization";
import { checkAdmin } from "../../store/user";
import "./styles.scss";

export const AvelaDashboard = () => {
  const { isAvelaAdmin } = useSelector((state) => state.user.details);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAdmin());
  }, []);

  const handleSubmit = (values) => {
    setLoading(true);
    setSuccess(false);
    setError();
    dispatch(createOrganization(values))
      .then(unwrapResult)
      .then(() => {
        setSuccess(true);
        setError();
        setLoading(false);
      })
      .catch(() => {
        setError(
          "Something went wrong. Please contact the dev team and share the url along with the email and organization name entered above"
        );
        setLoading(false);
      });
  };

  return isAvelaAdmin ? (
    <div className="avela-dashboard">
      <Form name="createOrganization" onFinish={handleSubmit} layout="vertical">
        <Form.Item label="Organization Name" name="name">
          <Input />
        </Form.Item>

        <Form.Item
          label="User Account Email"
          name="email"
          rules={[{ required: true }]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item label="Variablized Name for Match" name="matchWord">
          <Input />
        </Form.Item>

        <Form.Item label="Variablized Name for Trial" name="trialWord">
          <Input />
        </Form.Item>

        <Form.Item label="Variablized Name for Candidate" name="candidatesName">
          <Input />
        </Form.Item>

        <Form.Item label="Variablized Name for Placement" name="placementsName">
          <Input />
        </Form.Item>

        <Button type="primary" htmlType="submit" disabled={loading}>
          Submit
        </Button>

        {error}
        {success && "Successfully created"}
      </Form>
    </div>
  ) : null;
};

import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import { DataTable } from "../../../../components/DataTable";
import { useMatchComplete } from "../../../../hooks/useMatchComplete";
import { preferencesImportDataPath } from "../../../../services/urlBuilder";
import {
  fetchMutualPreferences,
  updateMutualPreference,
} from "../../../../store/mutualPreferences";

export const Preferences = () => {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState();
  const { lotteryId, matchId } = useParams();
  const mutualPreferencesById = useSelector(
    (state) => state.mutualPreferences.byId
  );
  const mutualPreferenceIds = useMemo(
    () => Object.keys(mutualPreferencesById),
    [mutualPreferencesById]
  );
  const matchComplete = useMatchComplete(matchId);
  const { loading } = useSelector((state) => state.mutualPreferences) || {};

  useEffect(() => {
    if (lotteryId) {
      dispatch(fetchMutualPreferences({ lotteryId, matchId }))
        .then(unwrapResult)
        .then((result) => {
          if (result.length === 0) {
            setRedirectTo(preferencesImportDataPath(lotteryId, matchId));
          }
        });
    }
  }, [dispatch, lotteryId, matchId]);

  const rows = useMemo(() => {
    return mutualPreferenceIds.map((id) => ({
      ...mutualPreferencesById[id]?.details,
      avelaId: id,
    }));
  });

  const headers = useMemo(() => {
    const [first] = rows;
    const result = first ? Object.keys(first) : [];
    const index = result.indexOf("avelaId");
    if (index >= 0) {
      result.splice(index, 1);
    }
    return result;
  }, [rows]);

  const handleUpdateRow = (data) => {
    const { avelaId, ...restDetails } = data;
    const mutualPreference = mutualPreferencesById[avelaId];
    dispatch(
      updateMutualPreference({
        lotteryId,
        matchId,
        id: mutualPreference.id,
        data: { details: restDetails },
      })
    );
  };

  const editableKeys = matchComplete ? [] : headers;

  return (
    <div>
      <DataTable
        headers={headers}
        rows={rows}
        editableKeys={editableKeys}
        onUpdateRow={handleUpdateRow}
        loading={loading}
      />
      {redirectTo && <Redirect to={redirectTo} />}
    </div>
  );
};

export const CANDIDATES = "CANDIDATES";
export const PLACEMENTS = "PLACEMENTS";
export const CANDIDATE_IDENTIFIER = "CANDIDATE_IDENTIFIER";
export const CANDIDATE_PREFERENCE = "CANDIDATE_PREFERENCE";
export const PLACEMENT_IDENTIFIER = "PLACEMENT_IDENTIFIER";
export const PLACEMENT_PREFERENCE = "PLACEMENT_PREFERENCE";
export const CAPACITY = "CAPACITY";
export const PIE_CHART = "PIE_CHART";

export const HIGH = "high";
export const MEDIUM = "medium";
export const LOW = "low";
export const HML = [HIGH, MEDIUM, LOW];

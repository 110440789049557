import React, { useEffect, useState } from "react";
import { Button, Menu, Select } from "antd";
import { Link, Redirect, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { unwrapResult } from "@reduxjs/toolkit";
import { PlusOutlined } from "@ant-design/icons";

import {
  avelaDashboardPath,
  lotteriesPath,
  lotteryPath,
  matchPath,
} from "../../services/urlBuilder";
import { createMatch } from "../../store/matches";
import { AvelaLogo } from "../../images/AvelaLogo";
import { fetchLotteries } from "../../store/lotteries";
import { Auth } from "../../services/Auth";
import { MatchesNavList } from "./components/MatchesNavList";

import "./styles.scss";

export const NavMenu = () => {
  const { matchWord, trialWord } = useSelector(
    (state) => state.organization.details
  );
  const lotteriesById = useSelector((state) => state.lotteries.byId);
  const { lotteryId } = useParams();
  const lottery = lotteriesById[lotteryId];

  const { pathname } = useLocation();

  const { isAvelaAdmin } = useSelector((state) => state.user.details);
  const [redirectTo, setRedirectTo] = useState();
  const dispatch = useDispatch();

  const handleCreateMatch = () => {
    dispatch(createMatch({ lotteryId }))
      .then(unwrapResult)
      .then((match) => {
        setRedirectTo(matchPath(lotteryId, match.id));
      });
  };

  const handleLogout = () => {
    Auth.resetTokens();
    window.location = "https://avela.org/logout";
  };

  return (
    <div className="nav-menu">
      <div className="nav-top">
        <Link to={lotteriesPath()}>
          <AvelaLogo />
        </Link>
      </div>
      <div className="nav-center">
        <p className="lottery-name">{lottery?.name}</p>
        {lottery && (
          <>
            <MatchesNavList />
            <Button
              className="new-trial"
              type="text"
              onClick={handleCreateMatch}
            >
              <PlusOutlined />
              <FormattedMessage id="NEW_TRIAL" values={{ name: trialWord }} />
            </Button>
          </>
        )}
      </div>

      <div className="nav-bottom">
        <Menu mode="inline" selectedKeys={[pathname]}>
          <Menu.Item key={lotteriesPath()}>
            <Link to={lotteriesPath()}>
              <FormattedMessage
                id="MATCH_DASHBOARD"
                values={{ name: matchWord }}
              />
            </Link>
          </Menu.Item>
          {isAvelaAdmin && (
            <Menu.Item key={avelaDashboardPath()}>
              <Link to={avelaDashboardPath()}>Avela Dashboard</Link>
            </Menu.Item>
          )}
          <Menu.Item key="logout" onClick={handleLogout}>
            Logout
          </Menu.Item>
        </Menu>
      </div>

      {redirectTo && <Redirect to={redirectTo} />}
    </div>
  );
};

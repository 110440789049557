import { useMemo } from "react";
import { union } from "lodash";

export const useHeadersFromData = (rowData) => {
  const headers = useMemo(() => {
    window.union = union;
    return union(...rowData.map((row) => Object.keys(row)));
  }, [rowData]);

  return headers;
};

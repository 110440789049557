import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  lotteryPath,
  matchPath,
  matchPathDef,
} from "../../services/urlBuilder";
import { fetchLottery } from "../../store/lotteries";
import { fetchMatches, createMatch, duplicateMatch } from "../../store/matches";
import { MatchView } from "../MatchView";

export const LotteryPage = () => {
  const dispatch = useDispatch();
  const { lotteryId } = useParams();
  const { pathname } = useLocation();
  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    dispatch(fetchLottery({ lotteryId }));
    dispatch(fetchMatches({ lotteryId }))
      .then(unwrapResult)
      .then((matches) => {
        if (pathname === lotteryPath(lotteryId)) {
          const finalMatch = matches.find((m) => m.isFinalResult);

          if (finalMatch) {
            setRedirectTo(matchPath(lotteryId, finalMatch.id));
          } else if (matches.length > 0) {
            setRedirectTo(matchPath(lotteryId, matches[matches.length - 1].id));
          } else {
            dispatch(createMatch({ lotteryId }))
              .then(unwrapResult)
              .then((match) => {
                setRedirectTo(matchPath(lotteryId, match.id));
              });
          }
        }
      });
  }, [dispatch, lotteryId]);

  return (
    <div>
      <Switch>
        <Route path={matchPathDef()}>
          <MatchView />
        </Route>
      </Switch>

      {redirectTo && <Redirect to={redirectTo} />}
    </div>
  );
};

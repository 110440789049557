import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useIntl } from "react-intl";
import {
  BarChart,
  Bar,
  CartesianGrid,
  Legend,
  LabelList,
  XAxis,
  YAxis,
  Label,
} from "recharts";
import { range } from "lodash";
import { BRAND_PRIMARY_2, SECONDARY_3 } from "../../../../constants/colors";
import { HML } from "../../../Data/constants";
import "./styles.scss";

export const ResultsBarChart = () => {
  const { lotteryId, matchId } = useParams();
  const intl = useIntl();
  const matchings =
    useSelector((state) => state.matches.byId[matchId].matchings) || [];
  const { candidates = [], placements = [] } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};
  const { candidatesName, placementsName } =
    useSelector((state) => state.lotteries?.byId?.[lotteryId]) || {};

  const initializeHappiness = (text) => ({
    text,
    count: 0,
  });

  const isCandidateNumericRanking = useMemo(() => {
    const [firstMatching] = matchings;
    const { mutualRating } = firstMatching || {};
    const { candidateRating = {} } = mutualRating || {};

    return !!candidateRating.numericRanking;
  }, [matchings]);

  const isPlacementNumericRanking = useMemo(() => {
    const [firstMatching] = matchings;
    const { mutualRating } = firstMatching || {};
    const { placementRating = {} } = mutualRating || {};

    return !!placementRating.numericRanking;
  }, [matchings]);

  const numericData = useCallback(
    (ratingAttr) => {
      let data = [];

      const rankings = matchings
        .map((matching) => matching?.mutualRating?.[ratingAttr]?.numericRanking)
        .filter((ranking) => !isNaN(parseInt(ranking)));

      const min = Math.min(...rankings);
      const max = Math.max(...rankings);

      range(min, max + 1).forEach((rank) => {
        data[rank] = initializeHappiness(rank);
      });

      matchings.forEach((matching) => {
        const ranking = matching?.mutualRating?.[ratingAttr]?.numericRanking;
        if (ranking && data[ranking]) {
          data[ranking].count += 1;
        }
      });

      return data.filter((d) => !!d);
    },
    [matchings]
  );

  const hmlData = useCallback((ratingAttr) => {
    let data = [];

    HML.forEach((rank, i) => {
      data[i] = initializeHappiness(rank);
    });

    matchings.forEach((matching) => {
      const ranking = matching?.mutualRating?.[ratingAttr]?.bucketIndex;
      if (data[ranking]) {
        data[ranking].count += 1;
      }
    });

    return data.filter((d) => !!d);
  });

  const candidatesData = useMemo(() => {
    const data = isCandidateNumericRanking
      ? numericData("candidateRating")
      : hmlData("candidateRating");

    return data.filter((d) => !!d);
  }, [isCandidateNumericRanking, numericData, hmlData]);

  const placementsData = useMemo(() => {
    const data = isPlacementNumericRanking
      ? numericData("placementRating")
      : hmlData("placementRating");

    return data.filter((d) => !!d);
  }, [isPlacementNumericRanking, numericData, hmlData]);

  const numUnmatchedCandidates = candidates.length - matchings.length;
  const numUnmatchedPlacements = useMemo(() => {
    const numPlacements = placements.reduce((acc, placement) => {
      const capacity = parseInt(placement.details?.[placement.capacityAttr]);
      acc += isNaN(capacity) ? 1 : capacity;
      return acc;
    }, 0);
    return numPlacements - matchings.length;
  }, [placements, matchings]);

  return (
    <div className="results-bar-charts">
      <div className="total-counts-row">
        <div className="total-count">Matched: {matchings.length}</div>
        <div className="total-count">Unmatched: {numUnmatchedCandidates}</div>
      </div>

      <div className="chart">
        <BarChart
          width={500}
          height={232}
          data={candidatesData}
          margin={{ top: 20 }}
        >
          <XAxis dataKey="text" tickLine={false} dy={2}>
            <Label
              value="Preference"
              position="insideBottomLeft"
              dx={-65}
              dy={-4}
            />
          </XAxis>

          <YAxis
            orientation="left"
            tickLine={false}
            allowDecimals={false}
            axisLine={false}
            dx={-42}
            width={80}
          />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />

          <Legend
            iconType="plainline"
            align="right"
            verticalAlign="middle"
            wrapperStyle={{ top: "-33px" }}
          />

          <Bar dataKey="count" name={candidatesName} fill={BRAND_PRIMARY_2}>
            <LabelList dataKey="count" position="top" />
          </Bar>
        </BarChart>
      </div>

      <div className="total-counts-row">
        <div className="total-count">Matched: {matchings.length}</div>
        <div className="total-count">Unmatched: {numUnmatchedPlacements}</div>
      </div>

      <div className="chart">
        <BarChart
          width={500}
          height={232}
          data={placementsData}
          margin={{ top: 20 }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="text" tickLine={false} dy={2}>
            <Label
              value="Preference"
              position="insideBottomLeft"
              dx={-65}
              dy={-4}
            />
          </XAxis>
          <YAxis
            orientation="left"
            tickLine={false}
            allowDecimals={false}
            axisLine={false}
            dx={-42}
            width={80}
          />

          <Legend
            iconType="plainline"
            align="right"
            wrapperStyle={{ top: "-33px" }}
          />

          <Bar dataKey="count" name={placementsName} fill={SECONDARY_3}>
            <LabelList dataKey="count" position="top" />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../services/api";
import { fetchMatch } from "../matches";

export const createPreferences = createAsyncThunk(
  "preferences/create",
  async (data, thunkAPI) => {
    const response = await api.createPreferences(data);
    return response.data;
  }
);

export const updatePreference = createAsyncThunk(
  "preferences/update",
  async (data, thunkAPI) => {
    const response = await api.updatePreference(data);
    return response.data;
  }
);

export const fetchPreferences = createAsyncThunk(
  "preferences/fetch",
  async (data, thunkAPI) => {
    const response = await api.fetchPreferences(data);
    return response.data;
  }
);

const preferencesSlice = createSlice({
  name: "preferences",
  initialState: {
    loading: false,
    creating: false,
    byId: {},
  },
  reducers: {},
  extraReducers: {
    [createPreferences.pending]: (state, action) => {
      state.creating = true;
    },
    [createPreferences.fulfilled]: (state, action) => {
      state.creating = false;
    },
    [createPreferences.rejected]: (state, action) => {
      state.creating = false;
    },
    [fetchPreferences.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPreferences.fulfilled]: (state, action) => {
      const ratings = action.payload ?? [];
      state.loading = false;
      state.byId = ratings.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
    },
  },
});

const { reducer } = preferencesSlice;

export default reducer;

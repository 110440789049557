import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { uniq } from "lodash";
import { useIntl } from "react-intl";
import { unwrapResult } from "@reduxjs/toolkit";

import { MapColumns } from "../../components";
import { PLACEMENT_IDENTIFIER } from "../../constants";
import { createPlacements } from "../../../../store/placements";
import { setPlacementMapping } from "../../../../store/dataUpload";
import { useQuery } from "../../../../hooks/useQuery";
import { useMappingOptions } from "../../hooks/useMappingOptions";
import { placementsDataPath } from "../../../../services/urlBuilder";

export const PlacementsMapData = () => {
  const { lotteryId, matchId } = useParams();
  const [redirectTo, setRedirectTo] = useState();
  const query = useQuery();
  const isUpload = query.get("upload") === "true";
  const dispatch = useDispatch();
  const intl = useIntl();
  const { headers: uploadHeaders, rows: uploadRows } = useSelector(
    (state) => state.dataUpload.placements
  );
  const { placementAttrs: savedHeaders } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};
  const { creating } = useSelector((state) => state.placements);
  const { placementMappingOptions } = useMappingOptions();

  const placementsAttributeMapping = useSelector((state) => {
    return state.dataUpload?.placements?.attributeMapping;
  });

  const handleSubmitPlacements = (data) => {
    if (isUpload) {
      dispatch(
        createPlacements({
          ...data,
          rows: uploadRows,
          headers: uploadHeaders,
        })
      )
        .then(unwrapResult)
        .then(() => {
          setRedirectTo(placementsDataPath(lotteryId, matchId));
        });
    }
  };

  const handleChangePlacementMapping = (attributeMapping) => {
    dispatch(setPlacementMapping(attributeMapping));
  };

  const getCustomValidator = (option) => {
    return () => ({
      validator(_, value) {
        if (option.value === PLACEMENT_IDENTIFIER) {
          const selectedColumnValues = uploadRows.map((row) => row[value]);
          if (
            selectedColumnValues.length === uniq(selectedColumnValues).length
          ) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(intl.formatMessage({ id: "ERROR_COLUMN_UNIQUENESS" }))
          );
        }

        return Promise.resolve();
      },
    });
  };

  return (
    <>
      <MapColumns
        options={placementMappingOptions}
        attributeMapping={placementsAttributeMapping}
        onSubmit={handleSubmitPlacements}
        submitting={creating}
        onChangeMapping={handleChangePlacementMapping}
        headers={isUpload ? uploadHeaders : savedHeaders}
        exampleRow={uploadRows[0]}
        customValidator={getCustomValidator}
      />
      {redirectTo && <Redirect to={redirectTo} />}
    </>
  );
};

import { useMemo } from "react";
import { useLocation } from "react-router";

export const useHashParams = () => {
  const { hash } = useLocation();

  return useMemo(() => {
    const params = {};
    const [, values] = hash.split("#");
    const splitValues = values.split("&");

    splitValues.forEach((splitValue) => {
      const [key, value] = splitValue.split("=");
      params[key] = value;
    });

    return params;
  }, [hash]);
};

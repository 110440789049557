import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router";
import { candidatesMapDataPath } from "../../../../services/urlBuilder";
import { setCandidateData } from "../../../../store/dataUpload";

import { FileUpload } from "../../components/FileUpload";

export const CandidatesDataImport = () => {
  const { lotteryId, matchId } = useParams();
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState();

  const handleUpload = (headers, rows) => {
    dispatch(setCandidateData({ headers, rows }));
    setRedirectTo(candidatesMapDataPath(lotteryId, matchId, { upload: true }));
  };

  return (
    <>
      <FileUpload onUpload={handleUpload} />
      {redirectTo && <Redirect to={redirectTo} />}
    </>
  );
};

import React, { useState } from "react";
import { Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { Button } from "../../components/Button";
import { confirmTOS } from "../../store/user";
import { lotteriesPath } from "../../services/urlBuilder";
import "./styles.scss";

export const TermsOfService = () => {
  const [accepted, setAccepted] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const dispatch = useDispatch();

  const handleChange = (e) => setAccepted(e.target.checked);
  const handleCancel = () => {
    window.location.href = "https://avela.org";
  };

  const handleAcceptTOS = () => {
    dispatch(confirmTOS())
      .then(unwrapResult)
      .then(() => {
        setRedirectTo(lotteriesPath());
      });
  };

  return (
    <div className="terms-of-service">
      <h3>Avela Terms of Service</h3>
      <div className="tos-container">
        <object
          data="https://avela-static.s3-us-west-2.amazonaws.com/avela-tos-april-2021.pdf"
          type="application/pdf"
        >
          <iframe src="https://docs.google.com/viewer?url=https://avela-static.s3-us-west-2.amazonaws.com/avela-tos-april-2021.pdf&embedded=true"></iframe>
        </object>
      </div>
      <div className="accept-tos-section">
        <Checkbox checked={accepted} onChange={handleChange}>
          I agree to the Avela Terms of Service
        </Checkbox>
      </div>
      <Button
        className="accept-tos-button"
        type="outlined"
        onClick={handleAcceptTOS}
        disabled={!accepted}
      >
        Launch Avela
      </Button>
      <Button type="text" className="cancel-tos" onClick={handleCancel}>
        Cancel
      </Button>

      {redirectTo && <Redirect to={redirectTo} />}
    </div>
  );
};

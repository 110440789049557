import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../services/api";

export const fetchLotteries = createAsyncThunk(
  "lotteries/fetch",
  async (data, thunkAPI) => {
    const response = await api.fetchLotteries(data);
    return response.data;
  }
);

export const fetchLottery = createAsyncThunk(
  "lotteries/get",
  async (data, thunkAPI) => {
    const response = await api.fetchLottery(data);
    return response.data;
  }
);

export const createLottery = createAsyncThunk(
  "lotteries/create",
  async (data, thunkAPI) => {
    const response = await api.createLottery(data);
    return response.data;
  }
);

export const updateLottery = createAsyncThunk(
  "lotteries/update",
  async (data, thunkAPI) => {
    const response = await api.updateLottery(data);
    return response.data;
  }
);

export const deleteLottery = createAsyncThunk(
  "lotteries/delete",
  async (data, thunkAPI) => {
    const response = await api.deleteLottery(data);
    return response.data;
  }
);

const lotteriesSlice = createSlice({
  name: "lotteries",
  initialState: {
    byId: {},
    currentId: null,
  },
  reducers: {},
  extraReducers: {
    [fetchLotteries.fulfilled]: (state, action) => {
      state.byId = {};
      action.payload.forEach((lottery) => {
        state.byId[lottery.id] = lottery;
      });
    },
    [fetchLottery.fulfilled]: (state, action) => {
      const {
        payload,
        payload: { id },
      } = action;
      state.byId[id] = payload;
      state.currentId = id;
    },
    [updateLottery.fulfilled]: (state, action) => {
      const { payload } = action;
      state.byId[payload.id] = payload;
    },
  },
});

const { reducer } = lotteriesSlice;

export default reducer;

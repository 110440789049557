import React from "react";

export const AvelaLogo = () => (
  <svg
    width="48"
    height="44"
    viewBox="0 0 48 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.4768 43.5762L24.1799 29.5697L24.1467 1.17456L47.4768 43.5762Z"
      fill="#5F3795"
    />
    <path
      d="M47.4771 43.9211C47.4131 43.9212 47.3502 43.9039 47.2954 43.8711L23.9985 29.8637C23.9473 29.833 23.905 29.7898 23.8756 29.7383C23.8463 29.6868 23.8308 29.6288 23.8307 29.5697L23.7975 1.17542C23.7955 1.09729 23.8205 1.02082 23.8684 0.958545C23.9162 0.896267 23.9841 0.851872 24.0609 0.832634C24.1377 0.813396 24.2188 0.820455 24.291 0.852655C24.3631 0.884855 24.4221 0.940286 24.4582 1.00987L47.7918 43.4115C47.8207 43.4641 47.8353 43.5232 47.8341 43.583C47.8329 43.6428 47.816 43.7013 47.785 43.7527C47.7539 43.8041 47.7099 43.8467 47.6571 43.8762C47.6044 43.9057 47.5448 43.9212 47.4841 43.9211H47.4771ZM24.5298 29.3757L46.5525 42.6157L24.4984 2.53607L24.5298 29.3757Z"
      fill="#372B73"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0877 1.146L24.1812 29.5437L0.949829 43.6554L24.0877 1.146Z"
      fill="white"
    />
    <path
      d="M0.949547 43.9999C0.889034 43.9999 0.829556 43.9844 0.776934 43.9549C0.724312 43.9255 0.680348 43.883 0.649343 43.8317C0.618339 43.7805 0.601356 43.7221 0.600056 43.6624C0.598757 43.6027 0.613185 43.5437 0.641929 43.4912L23.7789 0.98262C23.8156 0.914673 23.8743 0.860797 23.9456 0.829569C24.0169 0.798342 24.0968 0.79156 24.1725 0.810303C24.2482 0.829047 24.3153 0.872238 24.3632 0.932999C24.4112 0.993759 24.4371 1.06859 24.4369 1.14559L24.5304 29.5433C24.5306 29.6024 24.5153 29.6606 24.4861 29.7123C24.4568 29.7639 24.4146 29.8073 24.3635 29.8382L1.13307 43.9439C1.0784 43.9791 1.01483 43.9985 0.949547 43.9999ZM23.7422 2.50882L1.87153 42.6902L23.8304 29.351L23.7422 2.50882Z"
      fill="#372B73"
    />
  </svg>
);

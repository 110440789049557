import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "antd/dist/antd.css";

import { AuthCallback } from "../AuthCallback";
import { Lotteries } from "../Lotteries";
import { LotteryPage } from "../LotteryPage";
import { AvelaDashboard } from "../AvelaDashboard";
import { Layout } from "../Layout";
import { TermsOfService } from "../TermsOfService";
import { fetchOrganization } from "../../store/organization";
import { fetchUser } from "../../store/user";
import { Auth } from "../../services/Auth";
import {
  avelaDashboardPathDef,
  homePathDef,
  lotteriesPathDef,
  lotteryPathDef,
  authCallbackDef,
  lotteriesPath,
  termsOfServicePath,
} from "../../services/urlBuilder";
import { unwrapResult } from "@reduxjs/toolkit";

export const App = () => {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    dispatch(fetchOrganization());
    dispatch(fetchUser())
      .then(unwrapResult)
      .then((user) => {
        if (!user.tosAccepted) {
          setRedirectTo(termsOfServicePath());
        }
      });
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path={homePathDef()}>
          {Auth.isLoggedIn() && <Redirect to={lotteriesPath()} />}
        </Route>

        <Route exact path={authCallbackDef()}>
          <AuthCallback />
        </Route>

        <Route exact path={termsOfServicePath()}>
          <TermsOfService />
        </Route>

        <Route exact path={lotteriesPathDef()}>
          <Layout>
            <Lotteries />
          </Layout>
        </Route>

        <Route path={lotteryPathDef()}>
          <Layout>
            <LotteryPage />
          </Layout>
        </Route>

        <Route path={avelaDashboardPathDef()}>
          <Layout>
            <AvelaDashboard />
          </Layout>
        </Route>
      </Switch>
      {redirectTo && <Redirect to={redirectTo} />}
    </Router>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { useDrop } from "react-dnd";

import { DRAGGABLE_ATTRIBUTE } from "../../constants";
import { DraggableAttribute } from "../DraggableAttribute";
import "./styles.scss";
import { matchPath } from "../../../../services/urlBuilder";

export const DroppableList = ({ attributes, onChange, onDrop }) => {
  const [, drop] = useDrop({
    accept: DRAGGABLE_ATTRIBUTE,
    drop: (item) => {
      onDrop(item.index);
    },
  });

  return (
    <div className="droppable-list" ref={drop}>
      {attributes.map((attribute) => (
        <DraggableAttribute
          key={attribute.text}
          attribute={attribute}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

DroppableList.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  onDrop: PropTypes.func,
};

DroppableList.defaultProps = {
  attributes: [],
  onChange: () => {},
  onDrop: () => {},
};

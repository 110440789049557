import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout as AntdLayout } from "antd";
import { NavMenu } from "../../components/NavMenu";

import "./styles.scss";

export const Layout = ({ children }) => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const handleCollapse = (collapsed) => setSiderCollapsed(collapsed);

  return (
    <AntdLayout style={{ height: "100vh" }}>
      <AntdLayout.Sider
        className="layout-sider"
        theme="light"
        collapsible={false}
        collapsed={siderCollapsed}
        onCollapse={handleCollapse}
      >
        <NavMenu />
      </AntdLayout.Sider>
      <AntdLayout.Content>{children}</AntdLayout.Content>
    </AntdLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

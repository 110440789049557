import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";
import {
  shallowCamelCase,
  shallowCamelCaseArray,
  shallowSnakeCase,
  shallowSnakeCaseArray,
} from ".";
import { Auth } from "./Auth";

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

const getDefaultgConfig = () => ({
  headers: {
    Authorization: `Bearer ${Auth.idToken}`,
  },
});

const axiosShallowTransform = axios.create();
const axiosDeepTransform = axios.create();
const handleUnauthorized = (error) => {
  if (error?.response?.status === 401) {
    Auth.resetTokens();
    Auth.login();
  }
};

axiosShallowTransform.interceptors.request.use((config) => {
  const { data } = config;
  if (data) {
    config.data = Array.isArray(data)
      ? shallowSnakeCaseArray(data)
      : shallowSnakeCase(data);
  }

  return config;
});

axiosShallowTransform.interceptors.response.use((response) => {
  const { data = {} } = response;
  return {
    ...response,
    data: Array.isArray(data)
      ? shallowCamelCaseArray(data)
      : shallowCamelCase(response.data),
  };
}, handleUnauthorized);

axiosDeepTransform.interceptors.request.use((config) => {
  if (config.data) {
    config.data = snakeCaseKeys(config.data);
  }

  return config;
});

axiosDeepTransform.interceptors.response.use((response) => {
  const { data = {} } = response;
  return {
    ...response,
    data: camelCaseKeys(data, {
      deep: true,
      stopPaths: [
        "matchings.candidate.details",
        "matchings.placement.details",
        "matchings.candidate_rating.details",
        "matchings.placement_rating.details",
        "matchings.mutual_rating.details",
        "details",
        "ratable.details",
        "rater.details",
        "data_set.candidates.details",
        "data_set.placements.details",
      ],
    }),
  };
}, handleUnauthorized);

export const createOrganization = (data) => {
  return axiosDeepTransform.post("/organizations", data, getDefaultgConfig());
};

export const fetchOrganizations = () => {
  return axiosDeepTransform.get("/organizations", getDefaultgConfig());
};

export const fetchUser = () => {
  return axiosDeepTransform.get("/users/current", getDefaultgConfig());
};

export const fetchLotteries = ({ organizationId }) => {
  return axiosDeepTransform.get(
    `/organizations/${organizationId}/lotteries`,
    getDefaultgConfig()
  );
};

export const fetchLottery = ({ lotteryId }) => {
  return axiosDeepTransform.get(`/lotteries/${lotteryId}`, getDefaultgConfig());
};

export const createLottery = ({ organizationId, ...data }) => {
  return axiosDeepTransform.post(
    `/organizations/${organizationId}/lotteries`,
    data,
    getDefaultgConfig()
  );
};

export const updateLottery = ({ lotteryId, ...data }) => {
  return axiosDeepTransform.patch(
    `/lotteries/${lotteryId}`,
    data,
    getDefaultgConfig()
  );
};

export const deleteLottery = ({ lotteryId }) => {
  return axiosDeepTransform.delete(
    `/lotteries/${lotteryId}`,
    getDefaultgConfig()
  );
};

export const fetchMatches = ({ lotteryId }) => {
  return axiosDeepTransform.get(
    `/lotteries/${lotteryId}/matches`,
    getDefaultgConfig()
  );
};

export const fetchMatch = ({ lotteryId, matchId }) => {
  return axiosDeepTransform.get(
    `/lotteries/${lotteryId}/matches/${matchId}`,
    getDefaultgConfig()
  );
};

export const createMatch = ({ lotteryId }) => {
  return axiosDeepTransform.post(
    `/lotteries/${lotteryId}/matches`,
    null,
    getDefaultgConfig()
  );
};

export const updateMatch = ({ lotteryId, matchId, ...data }) => {
  return axiosDeepTransform.patch(
    `/lotteries/${lotteryId}/matches/${matchId}`,
    data,
    getDefaultgConfig()
  );
};

export const duplicateMatch = ({ lotteryId, matchId }) => {
  return axiosDeepTransform.post(
    `/lotteries/${lotteryId}/matches/${matchId}/duplicate`,
    null,
    getDefaultgConfig()
  );
};

export const deleteMatch = ({ lotteryId, matchId }) => {
  return axiosDeepTransform.delete(
    `/lotteries/${lotteryId}/matches/${matchId}`,
    getDefaultgConfig()
  );
};

export const fetchCandidates = ({ lotteryId, matchId }) => {
  return axiosDeepTransform.get(
    `/lotteries/${lotteryId}/matches/${matchId}/candidates`,
    getDefaultgConfig()
  );
};

export const createCandidates = ({ lotteryId, matchId, ...dataSet }) => {
  return axiosShallowTransform.post(
    `/lotteries/${lotteryId}/matches/${matchId}/candidates/batch_create`,
    dataSet,
    getDefaultgConfig()
  );
};

export const fetchPlacements = ({ lotteryId, matchId }) => {
  return axiosShallowTransform.get(
    `/lotteries/${lotteryId}/matches/${matchId}/placements`,
    getDefaultgConfig()
  );
};

export const createPlacements = ({ lotteryId, matchId, ...dataSet }) => {
  return axiosShallowTransform.post(
    `/lotteries/${lotteryId}/matches/${matchId}/placements/batch_create`,
    dataSet,
    getDefaultgConfig()
  );
};

export const updatePlacement = ({
  lotteryId,
  matchId,
  placementId,
  ...data
}) => {
  return axiosShallowTransform.patch(
    `/lotteries/${lotteryId}/matches/${matchId}/placements/${placementId}`,
    data,
    getDefaultgConfig()
  );
};

export const fetchMutualPreferences = ({ lotteryId, matchId }) => {
  return axiosDeepTransform.get(
    `/lotteries/${lotteryId}/matches/${matchId}/mutual_ratings`,
    getDefaultgConfig()
  );
};

export const updateMutualPreference = ({ lotteryId, matchId, id, data }) => {
  return axiosShallowTransform.patch(
    `/lotteries/${lotteryId}/matches/${matchId}/mutual_ratings/${id}`,
    data,
    getDefaultgConfig()
  );
};

export const fetchPreferences = ({ lotteryId, matchId }) => {
  return axiosDeepTransform.get(
    `/lotteries/${lotteryId}/matches/${matchId}/ratings`,
    getDefaultgConfig()
  );
};

export const createPreferences = ({ lotteryId, matchId, ...dataSet }) => {
  return axiosShallowTransform.post(
    `/lotteries/${lotteryId}/matches/${matchId}/ratings/batch_create`,
    dataSet,
    getDefaultgConfig()
  );
};

export const updatePreference = ({ lotteryId, matchId, id, data }) => {
  return axiosShallowTransform.patch(
    `/lotteries/${lotteryId}/matches/${matchId}/ratings/${id}`,
    data,
    getDefaultgConfig()
  );
};

export const createMatchResult = ({ lotteryId, matchId }) => {
  return axiosDeepTransform.post(
    `/lotteries/${lotteryId}/matches/${matchId}/run`,
    null,
    getDefaultgConfig()
  );
};

export const updateMatchConfiguration = ({
  lotteryId,
  matchId,
  matchConfigurationId,
  data,
}) => {
  return axiosDeepTransform.patch(
    `/lotteries/${lotteryId}/matches/${matchId}/match_configurations/${matchConfigurationId}`,
    data,
    getDefaultgConfig()
  );
};

export const checkAdmin = () => {
  return axiosDeepTransform.post(
    "/users/update_admin",
    {},
    getDefaultgConfig()
  );
};

export const confirmTOS = () => {
  return axiosDeepTransform.post(`/users/confirm_tos`, {}, getDefaultgConfig());
};

export const verifyEmail = () => {
  return axiosDeepTransform.post(
    `/users/update_email_verified`,
    {},
    getDefaultgConfig()
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import XLSX from "xlsx";
import { Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { unwrapResult } from "@reduxjs/toolkit";
import { TableOutlined, BarChartOutlined } from "@ant-design/icons";

import { useHeadersFromData } from "../../hooks/useHeadersFromData";
import { useQuery } from "../../hooks/useQuery";
import { DataTable } from "../../components/DataTable";
import { Button } from "../../components/Button";
import { TabNav } from "../../components/TabNav";
import { ResultsBarChart } from "./components/ResultsBarChart";
import { lotteryResultsPath } from "../../services/urlBuilder";
import {
  GRAPH_TAB,
  MATCHED_TAB,
  UNMATCHED_CANDIDATES_TAB,
  UNMATCHED_PLACEMENTS_TAB,
} from "./constants";
import { ResultsPieChart } from "./components/ResultsPieChart";

import "./styles.scss";
import { fetchMatch, updateMatch } from "../../store/matches";
import { fetchLottery } from "../../store/lotteries";
import { useMatchedData } from "./hooks/useMatchedData";
import { TabNavDropdown } from "../../components/TabNav/TabNavDropdown";
import { delay } from "lodash";

export const MatchResults = () => {
  const { lotteryId, matchId } = useParams();
  const dispatch = useDispatch();
  const query = useQuery();
  const tab = query.get("tab");
  const { id: dataMatchId } =
    useSelector((state) => state.matchResults?.byMatchId?.[matchId]) || {};
  const { matchWord, trialWord } =
    useSelector((state) => state.organization?.details) || {};
  const { name: lotteryName } =
    useSelector((state) => state.lotteries?.byId?.[lotteryId]) || {};
  const { candidatesName, placementsName, isFinalResult } =
    useSelector((state) => state.lotteries?.byId?.[lotteryId]) || {};
  const { pieChartAttr } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};

  const { matched, unmatchedCandidates, unmatchedPlacements } =
    useMatchedData(matchId);

  const matchedHeaders = useHeadersFromData(matched);
  const unmatchedCandidateHeaders = useHeadersFromData(unmatchedCandidates);
  const unmatchedPlacementHeaders = useHeadersFromData(unmatchedPlacements);

  const handleExport = async () => {
    const matchedWorkbook = XLSX.utils.book_new();
    const unmatchedCandidatesWorkbook = XLSX.utils.book_new();
    const unmatchedPlacementsWorkbook = XLSX.utils.book_new();

    const matchedSheet = XLSX.utils.json_to_sheet(matched);
    const unmatchedCandidatesSheet =
      XLSX.utils.json_to_sheet(unmatchedCandidates);
    const unmatchedPlacementsSheet =
      XLSX.utils.json_to_sheet(unmatchedPlacements);

    XLSX.utils.book_append_sheet(matchedWorkbook, matchedSheet, "Matched");
    XLSX.utils.book_append_sheet(
      unmatchedCandidatesWorkbook,
      unmatchedCandidatesSheet,
      "Unmatched 1"
    );
    XLSX.utils.book_append_sheet(
      unmatchedPlacementsWorkbook,
      unmatchedPlacementsSheet,
      "Unmatched 2"
    );

    XLSX.writeFile(
      matchedWorkbook,
      `${lotteryName} ${trialWord} - Matched.csv`
    );
    setTimeout(() => {
      XLSX.writeFile(
        unmatchedCandidatesWorkbook,
        `${lotteryName} ${trialWord} - Unmatched ${candidatesName}.csv`
      );
      setTimeout(() => {
        XLSX.writeFile(
          unmatchedPlacementsWorkbook,
          `${lotteryName} ${trialWord} - Unmatched ${placementsName}.csv`
        );
      }, 1000);
    }, 1000);
  };

  const handleFinalize = () => {
    dispatch(updateMatch({ lotteryId, matchId, isFinalResult: true }))
      .then(unwrapResult)
      .then((response) => {
        dispatch(fetchMatch({ lotteryId, matchId }))
          .then(unwrapResult)
          .then(() => {
            dispatch(fetchLottery({ lotteryId }));
          });
      });
  };

  if (parseInt(matchId) !== dataMatchId) return null;

  const pathForTab = (tab) =>
    `${lotteryResultsPath(lotteryId, matchId)}?tab=${tab}`;
  const graphResultsPath = pathForTab(GRAPH_TAB);
  const matchedResultsPath = pathForTab(MATCHED_TAB);
  const unmatchedCandidatesPath = pathForTab(UNMATCHED_CANDIDATES_TAB);
  const unmatchedPlacementsPath = pathForTab(UNMATCHED_PLACEMENTS_TAB);

  const selectedPath = tab ? pathForTab(tab) : graphResultsPath;

  return (
    <div className="match-results">
      <div className="match-results-tab-nav">
        {selectedPath === graphResultsPath ? (
          <TabNav
            buttonClassName="match-results-section-button"
            selectedPath={selectedPath}
            values={[
              {
                path: graphResultsPath,
                content: <BarChartOutlined className="results-icon" />,
              },
              {
                path: matchedResultsPath,
                content: <TableOutlined className="results-icon" />,
              },
            ]}
          />
        ) : (
          <>
            <TabNav
              buttonClassName="match-results-section-button"
              selectedPath={selectedPath}
              values={[
                {
                  path: graphResultsPath,
                  content: <BarChartOutlined className="results-icon" />,
                },
              ]}
            />

            <TabNavDropdown
              icon={<TableOutlined className="results-icon" />}
              values={[
                {
                  path: matchedResultsPath,
                  text: "Matched",
                },
                {
                  path: unmatchedCandidatesPath,
                  text: `Unmatched ${candidatesName}`,
                },
                {
                  path: unmatchedPlacementsPath,
                  text: `Unmatched ${placementsName}`,
                },
              ]}
            />
          </>
        )}
      </div>

      {selectedPath === graphResultsPath && (
        <Row className="results-graphs">
          <Col span={12} offset={2}>
            <h6>
              <FormattedMessage
                id="MATCH_RESULT_HEADER"
                values={{ name: matchWord }}
              />
            </h6>
            <ResultsBarChart />
          </Col>
          <Col span={8} offset={2}>
            <h6>
              <FormattedMessage
                id="PIE_CHART_HEADER"
                values={{ name: pieChartAttr }}
              />
            </h6>
            <ResultsPieChart attribute={pieChartAttr} />
          </Col>
        </Row>
      )}

      {selectedPath === matchedResultsPath && (
        <DataTable rows={matched} headers={matchedHeaders} />
      )}

      {selectedPath === unmatchedCandidatesPath && (
        <DataTable
          rows={unmatchedCandidates}
          headers={unmatchedCandidateHeaders}
        />
      )}

      {selectedPath === unmatchedPlacementsPath && (
        <DataTable
          rows={unmatchedPlacements}
          headers={unmatchedPlacementHeaders}
        />
      )}

      <div className="result-buttons">
        <Button onClick={handleExport}>Export</Button>
        <Button
          type="primary"
          onClick={handleFinalize}
          disabled={isFinalResult}
          style={{ float: "right" }}
        >
          Finalize Result
        </Button>
      </div>
    </div>
  );
};

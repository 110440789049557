import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { useDrop } from "react-dnd";

import { DraggableAttribute } from "../DraggableAttribute";
import { DroppableList } from "../DroppableList";
import "./styles.scss";
import { DRAGGABLE_ATTRIBUTE } from "../../constants";
import classNames from "classnames";

export const Tiebreakers = ({ disabled, values, selected, onChange }) => {
  const hiddenAttributes = useMemo(() => {
    return selected
      ? selected.reduce((acc, cur) => {
          acc[cur.text] = cur;
          return acc;
        }, {})
      : [];
  }, [selected]);

  const handleDropLeftPanel = (item) => {
    const index = selected.findIndex((i) => i.text === item.text);
    const nextSelected = [...selected];
    nextSelected.splice(index, 1);
    onChange(nextSelected);
  };

  const handleDropRightPanel = (index) => {
    const item = values.find((value) => value.index === index);
    if (!selected.find((i) => i.text === item.text)) {
      onChange([...selected, item]);
    }
  };

  const [, drop] = useDrop({
    accept: DRAGGABLE_ATTRIBUTE,
    drop: handleDropLeftPanel,
  });

  const handleChangeOrder = (item, order) => {
    const nextValues = [...values];
    const value = nextValues.find((v) => v.id === item.id);
    value.order = order;
  };

  const handleChangeOrderLeftPanel = (item, order) => {
    const nextValues = [...values];
    const value = nextValues.find((v) => v.id === item.id);
    value.order = order;
    onChange(selected, nextValues);
  };

  const handleChangeOrderRightPanel = (item, order) => {
    const nextSelectedValues = [...selected];
    const value = nextSelectedValues.find((v) => v.id === item.id);
    value.order = order;
    onChange(nextSelectedValues);
  };

  return (
    <div className="tiebreakers">
      <Row>
        <Col xs={24} lg={11}>
          <div className="left-panel">
            <div ref={drop}>
              {values &&
                values.map((tiebreaker, index) => (
                  <DraggableAttribute
                    key={index}
                    attribute={tiebreaker}
                    empty={!!hiddenAttributes[tiebreaker.text]}
                    onChange={handleChangeOrderLeftPanel}
                  />
                ))}
            </div>
            {disabled && <div className="disabled-mask" />}
          </div>
        </Col>
        <Col xs={24} lg={13}>
          <div className="right-panel">
            <DroppableList
              attributes={selected}
              onDrop={handleDropRightPanel}
              onChange={handleChangeOrderRightPanel}
            />
            {disabled && <div className="disabled-mask" />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

Tiebreakers.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      order: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      order: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Tiebreakers.defaultProps = {
  disabled: false,
};

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, useParams, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Button, Input, Tooltip } from "antd";
import { TrophyOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  deleteMatch,
  fetchMatches,
  updateMatch,
} from "../../../../store/matches";
import { matchPath } from "../../../../services/urlBuilder";
import { MatchOptionsDropdown } from "../../../MatchOptionsDropdown";

import "./styles.scss";
import { useDoubleClick } from "../../../../hooks/useDoubleClick";

export const MatchNavMenuItem = ({ matchId, index, onDelete }) => {
  const { lotteryId } = useParams();
  const matchesById = useSelector((state) => state.matches.byId);
  const { trialWord } = useSelector((state) => state.organization.details);
  const { pathname } = useLocation();
  const [editingName, setEditingName] = useState();
  const [newName, setNewName] = useState();
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState();

  const linkRef = useRef();
  useDoubleClick({
    onSingleClick: () => {
      if (!pathname.includes(matchPath(lotteryId, matchId))) {
        setRedirectTo(matchPath(lotteryId, matchId));
      }
    },
    onDoubleClick: () => {
      handleRename();
    },
    ref: linkRef,
  });

  useEffect(() => {
    if (redirectTo) {
      setRedirectTo(undefined);
    }
  }, [redirectTo]);

  const match = matchesById[matchId];
  const handleRename = () => {
    setEditingName(true);
    setNewName(matchesById[matchId].name || trialWord);
  };

  const handleChangeName = (e) => {
    const {
      target: { value },
    } = e;
    setNewName(value);
  };

  const handleBlurName = () => () => {
    dispatch(updateMatch({ lotteryId, matchId, name: newName }))
      .then(unwrapResult)
      .then(() => {
        setEditingName(false);
      });
  };

  const handleDeleteMatch = (matchId, nextMatchId) => {
    onDelete(matchId, nextMatchId);
  };

  return (
    <>
      <div className="trial" ref={linkRef}>
        <span className="trial-index">
          {match.isFinalResult ? <TrophyOutlined /> : index + 1}
        </span>

        {editingName ? (
          <Input
            type="text"
            autoFocus
            value={newName}
            onChange={handleChangeName}
            onBlur={handleBlurName(matchId)}
            onPressEnter={handleBlurName(matchId)}
          />
        ) : (
          <>
            <div className="trial-name">
              <Tooltip title={match.name || trialWord} placement="topLeft">
                {match.name || trialWord}
              </Tooltip>
            </div>
            <div className="trial-options">
              <MatchOptionsDropdown
                matchId={matchId}
                onDelete={handleDeleteMatch}
                onRename={handleRename}
              />
            </div>
          </>
        )}
      </div>
      {redirectTo && <Redirect to={redirectTo} />}
    </>
  );
};

MatchNavMenuItem.propTypes = {
  matchId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
};

MatchNavMenuItem.defaultProps = {
  onDelete: () => {},
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { useIntl } from "react-intl";

import { MapColumns } from "../../components";
import { useQuery } from "../../../../hooks/useQuery";
import { unwrapResult } from "@reduxjs/toolkit";
import { createPreferences } from "../../../../store/preferences";
import { preferencesDataPath } from "../../../../services/urlBuilder";
import { setPreferenceMapping } from "../../../../store/dataUpload";
import { useMappingOptions } from "../../hooks/useMappingOptions";
import {
  CANDIDATE_IDENTIFIER,
  CANDIDATE_PREFERENCE,
  PLACEMENT_IDENTIFIER,
  PLACEMENT_PREFERENCE,
  HML,
} from "../../constants";
import { fetchMatch } from "../../../../store/matches";

export const PreferencesMapData = () => {
  const dispatch = useDispatch();
  const { lotteryId, matchId } = useParams();
  const [redirectTo, setRedirectTo] = useState();
  const query = useQuery();
  const isUpload = query.get("upload") === "true";
  const intl = useIntl();
  const { headers: uploadHeaders, rows: uploadRows } = useSelector(
    (state) => state.dataUpload.preferences
  );
  const { candidates = [], placements = [] } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};
  const { candidatesName, placementsName } =
    useSelector((state) => state.lotteries?.byId?.[lotteryId]) || {};
  const { preferenceMappingOptions } = useMappingOptions();
  const attributeMapping = useSelector((state) => {
    return state.dataUpload?.preferences?.attributeMapping;
  });
  const { creating } = useSelector((state) => state.preferences);

  const handleSubmit = (data) => {
    if (isUpload) {
      dispatch(
        createPreferences({
          ...data,
          rows: uploadRows,
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(fetchMatch({ matchId, lotteryId }));
          setRedirectTo(preferencesDataPath(lotteryId, matchId));
        });
    }
  };

  const handleChangeMapping = (attributeMapping) => {
    dispatch(setPreferenceMapping(attributeMapping));
  };

  const getCustomValidator = (option) => {
    return () => ({
      validator(_, value) {
        if (
          option.value === CANDIDATE_IDENTIFIER ||
          option.value === PLACEMENT_IDENTIFIER
        ) {
          const isCandidate = option.value === CANDIDATE_IDENTIFIER;
          const matchables = isCandidate ? candidates : placements;
          const columnValues = uploadRows.map((r) => String(r[value]));
          const hasMatchingId = !!matchables.find((m) =>
            columnValues.includes(String(m.uniqueIdentifier))
          );
          if (hasMatchingId) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(
              intl.formatMessage(
                { id: "ERROR_MATCH_IDENTIFIER" },
                { name: isCandidate ? candidatesName : placementsName }
              )
            )
          );
        }

        if (
          option.value === CANDIDATE_PREFERENCE ||
          option.value === PLACEMENT_PREFERENCE
        ) {
          const isHML = !uploadRows.find((r) => {
            return !HML.includes(String(r[value]).toLowerCase());
          });
          const isNumeric = !uploadRows.find((r) => {
            const int = parseInt(r[value]);
            return isNaN(int);
          });

          if (!isHML && !isNumeric) {
            return Promise.reject(
              new Error(intl.formatMessage({ id: "ERROR_PREFERENCE_FORMAT" }))
            );
          }
        }

        return Promise.resolve();
      },
    });
  };

  return (
    <>
      <MapColumns
        options={preferenceMappingOptions}
        attributeMapping={attributeMapping}
        onSubmit={handleSubmit}
        submitting={creating}
        onChangeMapping={handleChangeMapping}
        headers={isUpload ? uploadHeaders : []}
        exampleRow={uploadRows[0]}
        customValidator={getCustomValidator}
      />
      {redirectTo && <Redirect to={redirectTo} />}
    </>
  );
};

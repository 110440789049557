import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

import { Auth } from "../../services/Auth";
import { useHashParams } from "../../hooks/useHashParams";
import { lotteriesPath } from "../../services/urlBuilder";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, verifyEmail } from "../../store/user";
import { Loading } from "../../components/Loading";

export const AuthCallback = () => {
  const [redirectTo, setRedirectTo] = useState();
  const isEmailVerified = useSelector(
    (state) => state.user?.details?.isEmailVerified
  );
  const hashParams = useHashParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      hashParams &&
      !!hashParams["id_token"] &&
      !!hashParams["access_token"]
    ) {
      Auth.setTokens(hashParams);
      dispatch(fetchUser()).then(() => {
        if (isEmailVerified) {
          setRedirectTo(lotteriesPath());
        } else {
          dispatch(verifyEmail()).then(() => {
            setRedirectTo(lotteriesPath());
          });
        }
      });
    }
  }, [hashParams]);

  return (
    <div>
      <Loading />
      {redirectTo && <Redirect to={redirectTo} />}
    </div>
  );
};

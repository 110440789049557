import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect, useParams } from "react-router-dom";

import { DataTable } from "../../../../components/DataTable";
import { placementsImportDataPath } from "../../../../services/urlBuilder";
import { updatePlacement } from "../../../../store/placements";
import { fetchMatch } from "../../../../store/matches";
import { useMatchComplete } from "../../../../hooks/useMatchComplete";

export const Placements = () => {
  const dispatch = useDispatch();
  const { lotteryId, matchId } = useParams();
  const [redirectTo, setRedirectTo] = useState();
  const matchComplete = useMatchComplete(matchId);
  const { placements = [] } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};
  const { loading } = useSelector((state) => state.matches.byId[matchId]) || {};
  const { placementAttrs: headers } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};

  useEffect(() => {
    if (lotteryId) {
      dispatch(fetchMatch({ lotteryId, matchId }))
        .then(unwrapResult)
        .then((result) => {
          if (result.dataSet?.placements?.length === 0) {
            setRedirectTo(placementsImportDataPath(lotteryId, matchId));
          }
        });
    }
  }, [dispatch, lotteryId, matchId]);

  const rows = useMemo(() => {
    return [...placements]
      .sort((a, b) => a.id - b.id)
      .map((p) => ({
        avelaId: p.id,
        ...p.details,
      }));
  }, [placements]);

  const handleUpdateRow = useCallback(
    (data) => {
      const { avelaId: placementId } = data;
      const placement = placements.find((p) => p.id === placementId);

      dispatch(
        updatePlacement({
          lotteryId,
          matchId,
          placementId,
          details: data,
          placement: {
            capacity: data[placement.capacityAttr],
          },
        })
      );
    },
    [placements, lotteryId, matchId]
  );

  const [firstPlacement] = placements;
  const editableKeys = matchComplete ? [] : [firstPlacement?.capacityAttr];

  return (
    <div>
      <DataTable
        headers={headers || []}
        rows={rows}
        editableKeys={editableKeys}
        onUpdateRow={handleUpdateRow}
        loading={loading}
      />
      {redirectTo && <Redirect to={redirectTo} />}
    </div>
  );
};

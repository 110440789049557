export const homePath = () => "/";
export const homePathDef = homePath;

export const authCallbackDef = () => "/auth/callback";

export const lotteriesPath = () => "/lotteries";
export const lotteriesPathDef = lotteriesPath;

export const newLotteryPath = () => "/lotteries/new";
export const newLotteryPathDef = newLotteryPath;

export const lotteryPath = (lotteryId) => `/lotteries/${lotteryId}`;
export const lotteryPathDef = () => "/lotteries/:lotteryId";

export const matchPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}`;
export const matchPathDef = () => "/lotteries/:lotteryId/matches/:matchId";

export const lotteryDataPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data`;
export const lotteryDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data";

export const lotterySettingsPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}/settings`;
export const lotterySettingsPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/settings";

export const lotteryResultsPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}/results`;
export const lotteryResultsPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/results";

export const candidatesDataPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data/candidates`;
export const candidatesDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data/candidates";

export const candidatesImportDataPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data/candidates/import`;
export const candidatesImportDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data/candidates/import";

export const candidatesMapDataPath = (lotteryId, matchId, { upload } = {}) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data/candidates/map${
    upload ? "?upload=true" : ""
  }`;
export const candidatesMapDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data/candidates/map";

export const placementsDataPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data/placements`;
export const placementsDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data/placements";

export const placementsImportDataPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data/placements/import`;
export const placementsImportDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data/placements/import";

export const placementsMapDataPath = (lotteryId, matchId, { upload } = {}) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data/placements/map${
    upload ? "?upload=true" : ""
  }`;
export const placementsMapDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data/placements/map";

export const preferencesDataPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data/preferences`;
export const preferencesDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data/preferences";

export const preferencesImportDataPath = (lotteryId, matchId) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data/preferences/import`;
export const preferencesImportDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data/preferences/import";

export const preferencesMapDataPath = (lotteryId, matchId, { upload }) =>
  `/lotteries/${lotteryId}/matches/${matchId}/data/preferences/map${
    upload ? "?upload=true" : ""
  }`;
export const preferencesMapDataPathDef = () =>
  "/lotteries/:lotteryId/matches/:matchId/data/preferences/map";

export const avelaDashboardPath = () => "/avela-dashboard";
export const avelaDashboardPathDef = avelaDashboardPath;

export const termsOfServicePath = () => "/terms-of-service";

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Drawer, Form, Button, Input } from "antd";

import "./styles.scss";
import { useSelector } from "react-redux";

export const NewLotteryDrawer = ({ visible, onSubmit, onCancel }) => {
  const { candidatesName, placementsName, matchWord } = useSelector(
    (state) => state.organization.details
  );
  const intl = useIntl();

  const handleSubmit = (data) => {
    const result = {
      ...data,
      candidatesName: data.candidatesName || candidatesName,
      placementsName: data.placementsName || placementsName,
    };

    onSubmit(result);
  };

  return (
    <Drawer
      className="new-lottery-drawer"
      placement="left"
      visible={visible}
      width={480}
      closable={false}
    >
      <Form
        className="new-lottery-form"
        name="newLottery"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <h6>
          <FormattedMessage
            id="NEW_MATCH_HEADER"
            values={{ name: matchWord }}
          />
        </h6>

        <Form.Item name="name" rules={[{ required: true }]}>
          <Input
            placeholder={intl.formatMessage(
              { id: "NEW_MATCH_TITLE_PLACEHOLDER" },
              { name: matchWord }
            )}
          />
        </Form.Item>

        <div className="match-names-header">
          <FormattedMessage
            id="NEW_MATCH_VARIABLES_HEADER"
            values={{ name: matchWord }}
          />
        </div>
        <div className="new-lottery-names-row">
          <Form.Item className="new-lottery-field inline" name="candidatesName">
            <Input defaultValue={candidatesName} />
          </Form.Item>
          <span className="new-lottery-connector">and</span>
          <Form.Item className="new-lottery-field inline" name="placementsName">
            <Input defaultValue={placementsName} />
          </Form.Item>
        </div>

        <Button className="new-lottery-submit" type="primary" htmlType="submit">
          Confirm
        </Button>

        <div className="new-lottery-cancel">
          <Button type="link" className="cancel-button" onClick={onCancel}>
            <FormattedMessage id="CANCEL" />
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

NewLotteryDrawer.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

NewLotteryDrawer.defaultProps = {
  visible: true,
  onCancel: () => {},
  onSubmit: () => {},
};

import { Menu } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams, useRouteMatch } from "react-router-dom";
import { matchPath, matchPathDef } from "../../../../services/urlBuilder";
import { deleteMatch, fetchMatches } from "../../../../store/matches";
import { MatchNavMenuItem } from "../../../NavMenu/components/MatchNavMenuItem";

import "./styles.scss";

export const MatchesNavList = () => {
  const { lotteryId } = useParams();
  const { params: { matchId: currentMatchId } = {} } =
    useRouteMatch(matchPathDef()) || {};
  const matchesById = useSelector((state) => state.matches.byId);
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState();

  const handleDelete = (matchId, nextMatchId) => {
    dispatch(deleteMatch({ lotteryId, matchId })).then(() => {
      setRedirectTo(matchPath(lotteryId, nextMatchId));
    });
  };

  return (
    <>
      <Menu theme="light" mode="inline" selectedKeys={[currentMatchId]}>
        {matchesById &&
          Object.keys(matchesById).map((matchId, index) => {
            const match = matchesById[matchId];
            return match ? (
              <Menu.Item key={matchId} className="match-item">
                <MatchNavMenuItem
                  matchId={matchId}
                  index={index}
                  onDelete={handleDelete}
                />
              </Menu.Item>
            ) : null;
          })}
      </Menu>
      {redirectTo && <Redirect to={redirectTo} />}
    </>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import {
  candidatesDataPathDef,
  placementsDataPathDef,
  preferencesDataPathDef,
} from "../../../../services/urlBuilder";

import "./styles.scss";

export const CustomGridHeader = (props) => {
  const { lotteryId, matchId } = useParams();

  const isCandidatePath = !!useRouteMatch(candidatesDataPathDef());
  const isPlacementPath = !!useRouteMatch(placementsDataPathDef());
  const isPreferencePath = !!useRouteMatch(preferencesDataPathDef());

  const {
    candidates,
    placements,
    candidatePreferenceAttr,
    placementPreferenceAttr,
    preferencesCandidateIdAttr,
    preferencesPlacementIdAttr,
  } = useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};
  const { candidatesName, placementsName } =
    useSelector((state) => state.lotteries?.byId?.[lotteryId]) || {};
  const [firstCandidate] = candidates || [];
  const [firstPlacement] = placements || [];

  const [ascSort, setAscSort] = useState("inactive");
  const [descSort, setDescSort] = useState("inactive");

  const toggleSort = (event) => {
    if (ascSort === "active") {
      props.setSort("", event.shiftKey);
    } else if (descSort === "active") {
      props.setSort("asc", event.shiftKey);
    } else {
      props.setSort("desc", event.shiftKey);
    }
  };

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending() ? "active" : "inactive");
    setDescSort(props.column.isSortDescending() ? "active" : "inactive");
  };

  useEffect(() => {
    props.column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
  }, []);

  const renderColumnType = useCallback(() => {
    let type;
    const header = props.column.colId;

    if (isCandidatePath) {
      if (
        header === firstCandidate?.uniqueIdentifierAttr ||
        header === firstPlacement?.uniqueIdentifierAttr
      ) {
        type = "Unique Identifier";
      }
    } else if (isPlacementPath) {
      if (header === firstPlacement?.uniqueIdentifierAttr) {
        type = "Unique Identifier";
      } else if (header === firstPlacement?.capacityAttr) {
        type = "Capacity";
      }
    } else if (isPreferencePath) {
      if (header === candidatePreferenceAttr) {
        type = `${candidatesName} Preference`;
      } else if (header === placementPreferenceAttr) {
        type = `${placementsName} Preference`;
      } else if (header === preferencesCandidateIdAttr) {
        type = `${candidatesName} Unique Identifier`;
      } else if (header === preferencesPlacementIdAttr) {
        type = `${placementsName} Unique Identifier`;
      }
    }

    return type && <div className="custom-header-column-type">{type}</div>;
  }, [props.column.colId, isCandidatePath, isPlacementPath, isPreferencePath]);

  return (
    <div
      className="custom-grid-header"
      onClick={toggleSort}
      onTouchEnd={toggleSort}
    >
      <div className="custom-header-text">
        {props.displayName}
        {renderColumnType()}
      </div>

      {ascSort === "active" && (
        <span className="custom-header-sort">
          <UpOutlined />
        </span>
      )}

      {descSort === "active" && (
        <span className="custom-header-sort">
          <DownOutlined />
        </span>
      )}
    </div>
  );
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { Loading } from "../Loading";
import { CustomGridHeader } from "./components/CustomGridHeader";

export const DataTable = ({
  headers,
  rows,
  loading,
  editableKeys,
  onUpdateRow,
}) => {
  const frameworkComponents = {
    headerComponent: CustomGridHeader,
  };

  const editableKeysHash = useMemo(() => {
    if (editableKeys) {
      return editableKeys.reduce((acc, cur) => {
        acc[cur] = cur;
        return acc;
      }, {});
    }
  }, [editableKeys]);

  const handleCellValueChanged = (cell) => {
    const nextData = [...rows];
    const {
      newValue,
      data: { avelaId },
      colDef: { field },
    } = cell;

    const rowIndex = nextData
      .map((d) => String(d.avelaId))
      .indexOf(String(avelaId));

    nextData[rowIndex] = {
      ...nextData[rowIndex],
      [field]: newValue,
    };

    onUpdateRow(nextData[rowIndex]);
  };

  return loading ? (
    <Loading />
  ) : (
    <div
      className="ag-theme-alpine"
      style={{ width: "100%", fontFamily: "Open Sans" }}
    >
      <AgGridReact
        rowData={rows}
        domLayout="autoHeight"
        frameworkComponents={frameworkComponents}
      >
        {headers.map((header, index) => (
          <AgGridColumn
            key={index}
            field={header}
            resizable
            sortable
            editable={!!editableKeysHash[header]}
            valueSetter={handleCellValueChanged}
          />
        ))}
      </AgGridReact>
    </div>
  );
};

DataTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  editableKeys: PropTypes.arrayOf(PropTypes.string),
  onUpdateRow: PropTypes.func,
  loading: PropTypes.bool,
};

DataTable.defaultProps = {
  editableKeys: [],
  onUpdateRow: () => {},
  loading: false,
};

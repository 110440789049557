import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu, Modal } from "antd";
import { useIntl } from "react-intl";
import { unwrapResult } from "@reduxjs/toolkit";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { duplicateMatch } from "../../store/matches";
import { matchPath } from "../../services/urlBuilder";
const { confirm } = Modal;

export const MatchOptionsDropdown = ({ matchId, onDelete, onRename }) => {
  const matchesById = useSelector((state) => state.matches.byId);
  const { trialWord } = useSelector((state) => state.organization.details);
  const { lotteryId } = useParams();
  const [redirectTo, setRedirectTo] = useState();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleRename = () => {
    onRename(matchId);
  };

  const handleDuplicate = () => {
    dispatch(duplicateMatch({ lotteryId, matchId }))
      .then(unwrapResult)
      .then((data) => {
        setRedirectTo(matchPath(lotteryId, data.id));
      });
  };

  const handleDelete = () => {
    const matchIds = Object.keys(matchesById);
    const index = matchIds.indexOf(matchId);
    const nextMatchId =
      matchIds.length > index + 1 ? matchIds[index + 1] : matchIds[index - 1];

    confirm({
      title: intl.formatMessage({ id: "CONFIRM_DELETE" }, { name: trialWord }),
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        onDelete(matchId, nextMatchId);
      },
    });
  };

  const handleClick = ({ key }) => {
    if (key === "0") {
      handleRename();
    } else if (key === "1") {
      handleDuplicate();
    } else if (key === "2") {
      handleDelete();
    }
  };

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="0">Rename</Menu.Item>
      <Menu.Item key="1">Duplicate</Menu.Item>
      <Menu.Item key="2">Delete</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <DownOutlined />
        </a>
      </Dropdown>
      {redirectTo && <Redirect to={redirectTo} />}
    </>
  );
};

MatchOptionsDropdown.propTypes = {
  matchId: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
};

MatchOptionsDropdown.defaultProps = {
  onDelete: () => {},
  onRename: () => {},
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../services/api";

export const createPlacements = createAsyncThunk(
  "placements/create",
  async (data, thunkAPI) => {
    const response = await api.createPlacements(data);
    return response.data;
  }
);

export const updatePlacement = createAsyncThunk(
  "placements/update",
  async (data, thunkAPI) => {
    const response = await api.updatePlacement(data);
    return response.data;
  }
);

export const fetchPlacements = createAsyncThunk(
  "placements/fetch",
  async (data, thunkAPI) => {
    const response = await api.fetchPlacements(data);
    return response.data;
  }
);

const placementsSlice = createSlice({
  name: "placements",
  initialState: {
    loading: false,
    creating: false,
    byId: {},
  },
  reducers: {},
  extraReducers: {
    [createPlacements.pending]: (state, action) => {
      state.creating = true;
    },
    [createPlacements.fulfilled]: (state, action) => {
      const placements = action.payload ?? [];
      state.creating = false;
      state.byId = placements.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
    },
    [updatePlacement.fulfilled]: (state, action) => {
      const placement = action.payload;
      state.loading = false;
      state.byId[placement.id] = placement;
    },
    [fetchPlacements.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPlacements.fulfilled]: (state, action) => {
      const placements = action.payload ?? [];
      state.loading = false;
      state.byId = placements.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
    },
  },
});

const { reducer } = placementsSlice;

export default reducer;

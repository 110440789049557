import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircleOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { lotteryPath } from "../../services/urlBuilder";
import { NewLotteryDrawer } from "../NewLotteryDrawer";

import "./styles.scss";
import { createLottery } from "../../store/lotteries";

export const NewLotteryTile = () => {
  const { id: organizationId, matchWord } = useSelector(
    (state) => state.organization.details
  );
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleNewLottery = () => {
    setDrawerVisible(true);
  };

  const handleCloseNewLottery = () => {
    setDrawerVisible(false);
  };

  const handleCreateLottery = (data) => {
    dispatch(
      createLottery({
        ...data,
        organizationId,
      })
    )
      .then(unwrapResult)
      .then((lottery) => {
        setDrawerVisible(false);
        setRedirectTo(lotteryPath(lottery.id));
      });
  };

  return (
    <>
      <div className="new-lottery-tile" onClick={handleNewLottery}>
        <PlusCircleOutlined className="new-lottery-icon" />
        <div className="new-match-text">
          {intl.formatMessage({ id: "NEW_MATCH" }, { name: matchWord })}
        </div>
      </div>
      <NewLotteryDrawer
        visible={drawerVisible}
        onSubmit={handleCreateLottery}
        onCancel={handleCloseNewLottery}
      />
      {redirectTo && <Redirect to={redirectTo} />}
    </>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { useDrag } from "react-dnd";
import { Select } from "antd";
import classNames from "classnames";

import { DRAGGABLE_ATTRIBUTE } from "../../constants";
import "./styles.scss";

export const DraggableAttribute = ({
  attribute,
  disabled: disabledAttr,
  onChange,
  empty,
}) => {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: DRAGGABLE_ATTRIBUTE,
    item: attribute,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      className={classNames("draggable-attribute", {
        "is-dragging": collected.isDragging,
        "is-empty": empty,
      })}
      ref={drag}
    >
      {!empty && !collected.isDragging && (
        <>
          <div className="attribute-text">{attribute.text}</div>
          <Select
            className="order-dropdown"
            placeholder="Select Order"
            value={attribute.order}
            onChange={onChange.bind(this, attribute)}
          >
            <Select.Option value=">">Higher Values First</Select.Option>
            <Select.Option value="<">Lower Values First</Select.Option>
          </Select>
        </>
      )}
    </div>
  );
};

DraggableAttribute.propTypes = {
  attribute: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
  empty: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DraggableAttribute.defaultProps = {
  empty: false,
  onChange: () => {},
  disabled: false,
};

import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import { Menu } from "antd";
import {
  CloudServerOutlined,
  ControlOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

import {
  lotteryDataPath,
  lotteryDataPathDef,
  lotterySettingsPath,
  lotterySettingsPathDef,
  lotteryResultsPath,
  lotteryResultsPathDef,
  candidatesDataPath,
  matchPath,
  matchPathDef,
} from "../../services/urlBuilder";
import { fetchMatch } from "../../store/matches";
import DataPage from "../Data";
import { Configuration } from "../Configuration";
import { MatchResults } from "../MatchResults";
import { BottomButtons } from "../../components/BottomButtons";

import "./styles.scss";
import { Loading } from "../../components/Loading";

export const MatchView = () => {
  const [redirectTo, setRedirectTo] = useState();
  const { lotteryId, matchId } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { pairs = [] } =
    useSelector((state) => state.matchResults.byMatchId[matchId]) || {};
  const { ratings = [] } =
    useSelector((state) => state.matches.byId?.[matchId]?.dataSet) || {};

  useEffect(() => {
    if (lotteryId && matchId) {
      if (pairs.length > 0) {
        setRedirectTo(lotteryResultsPath(lotteryId, matchId));
      } else {
        dispatch(fetchMatch({ lotteryId, matchId })).then(
          ({ payload: { dataSet, matchings } = {} }) => {
            if (pathname === matchPath(lotteryId, matchId)) {
              const { ratings = [] } = dataSet || {};

              if (ratings.length === 0) {
                setRedirectTo(candidatesDataPath(lotteryId, matchId));
              } else if (matchings?.length === 0) {
                setRedirectTo(lotterySettingsPath(lotteryId, matchId));
              } else {
                setRedirectTo(lotteryResultsPath(lotteryId, matchId));
              }
            }
          }
        );
      }
    }
  }, [lotteryId, matchId]);

  const selectedKeyPath = useMemo(() => {
    if (pathname.includes(lotteryDataPath(lotteryId, matchId))) {
      return lotteryDataPath(lotteryId, matchId);
    } else {
      return pathname;
    }
  }, [lotteryId, matchId, pathname]);

  return (
    <div className="match-view">
      {redirectTo && <Redirect to={redirectTo} />}
      <Menu
        className="trial-nav"
        mode="horizontal"
        selectedKeys={[selectedKeyPath]}
      >
        <Menu.Item key={lotteryDataPath(lotteryId, matchId)}>
          <CloudServerOutlined />
          <Link to={lotteryDataPath(lotteryId, matchId)}>
            <FormattedMessage id="DATA" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key={lotterySettingsPath(lotteryId, matchId)}
          disabled={ratings.length === 0}
        >
          <ControlOutlined />
          <Link to={lotterySettingsPath(lotteryId, matchId)}>
            <FormattedMessage id="CONFIGURATION" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key={lotteryResultsPath(lotteryId, matchId)}
          disabled={pairs.length === 0}
        >
          <LineChartOutlined />
          <Link to={lotteryResultsPath(lotteryId, matchId)}>
            <FormattedMessage id="RESULTS" />
          </Link>
        </Menu.Item>
      </Menu>

      <br />

      <Switch>
        <Route exact path={matchPathDef()}>
          <Loading />
        </Route>

        <Route path={lotteryDataPathDef()}>
          <DataPage />
        </Route>

        <Route path={lotterySettingsPathDef()}>
          <Configuration />
        </Route>

        <Route path={lotteryResultsPathDef()}>
          <MatchResults />
        </Route>
      </Switch>
    </div>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../services/api";

export const fetchUser = createAsyncThunk(
  "user/get",
  async (data, thunkAPI) => {
    const response = await api.fetchUser(data);
    return response.data;
  }
);

export const checkAdmin = createAsyncThunk(
  "user/checkAdmin",
  async (data, thunkAPI) => {
    const response = await api.checkAdmin(data);
    return response.data;
  }
);

export const confirmTOS = createAsyncThunk(
  "user/confirmTOS",
  async (data, thunkAPI) => {
    const response = await api.confirmTOS(data);
    return response.data;
  }
);

export const verifyEmail = createAsyncThunk(
  "user/verifyEmail",
  async (data, thunkAPI) => {
    const response = await api.verifyEmail(data);
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    details: {},
  },
  reducers: {},
  extraReducers: {
    [fetchUser.fulfilled]: (state, action) => {
      state.details = action.payload;
    },
    [checkAdmin.fulfilled]: (state, action) => {
      state.details = action.payload;
    },
  },
});

const { reducer } = userSlice;

export default reducer;

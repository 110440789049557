import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../../services/api";
import { fetchMatch } from "../matches";

export const createCandidates = createAsyncThunk(
  "candidates/create",
  async (data, thunkAPI) => {
    const response = await api.createCandidates(data);
    return response.data;
  }
);

export const fetchCandidates = createAsyncThunk(
  "candidates/fetch",
  async (data, thunkAPI) => {
    const response = await api.fetchCandidates(data);
    return response.data;
  }
);

const appendCandidates = (byId, candidates) => {
  return {
    ...byId,
    ...candidates.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {}),
  };
};

const candidatesSlice = createSlice({
  name: "candidates",
  initialState: {
    loading: false,
    creating: false,
    byId: {},
  },
  reducers: {},
  extraReducers: {
    [createCandidates.pending]: (state, action) => {
      state.creating = true;
    },
    [createCandidates.fulfilled]: (state, action) => {
      state.creating = false;
    },
    [createCandidates.rejected]: (state, action) => {
      state.creating = false;
    },
    [fetchCandidates.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCandidates.fulfilled]: (state, action) => {
      const candidates = action.payload ?? [];
      state.loading = false;
      state.byId = appendCandidates(state.byId, candidates);
    },
    [fetchMatch.fulfilled]: (state, action) => {
      const {
        payload: {
          dataSet: { candidates = [] },
        },
      } = action;
      state.byId = appendCandidates(state.byId, candidates);
    },
  },
});

const { reducer } = candidatesSlice;

export default reducer;

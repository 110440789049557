import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { DataTable } from "../../../../components/DataTable";
import { candidatesImportDataPath } from "../../../../services/urlBuilder";
import { fetchMatch } from "../../../../store/matches";

export const Candidates = () => {
  const dispatch = useDispatch();
  const { lotteryId, matchId } = useParams();
  const [redirectTo, setRedirectTo] = useState();
  const { candidates = [] } =
    useSelector((state) => state.matches?.byId?.[matchId]?.dataSet) || {};
  const { loading } = useSelector((state) => state.matches.byId[matchId]) || {};

  useEffect(() => {
    if (lotteryId && matchId) {
      dispatch(fetchMatch({ lotteryId, matchId }))
        .then(unwrapResult)
        .then((result) => {
          if (result.dataSet?.candidates?.length === 0) {
            setRedirectTo(candidatesImportDataPath(lotteryId, matchId));
          }
        });
    }
  }, [dispatch, lotteryId, matchId]);

  const candidateRows = useMemo(() => {
    return [...candidates].sort((a, b) => a.id - b.id).map((c) => c.details);
  }, [candidates]);

  const headers = useMemo(() => {
    const [first] = candidateRows;

    return first ? Object.keys(first) : [];
  }, [candidateRows]);

  return (
    <div>
      <DataTable headers={headers} rows={candidateRows} loading={loading} />
      {redirectTo && <Redirect to={redirectTo} />}
    </div>
  );
};

import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  CANDIDATE_IDENTIFIER,
  CANDIDATE_PREFERENCE,
  CAPACITY,
  PIE_CHART,
  PLACEMENT_IDENTIFIER,
  PLACEMENT_PREFERENCE,
} from "../constants";

export const useMappingOptions = () => {
  const intl = useIntl();
  const { lotteryId } = useParams();
  const { candidatesName, placementsName } =
    useSelector((state) => state.lotteries?.byId?.[lotteryId]) || {};

  const candidateMappingOptions = useMemo(() => {
    return [
      {
        value: CANDIDATE_IDENTIFIER,
        text: intl.formatMessage(
          { id: "UNIQUE_IDENTIFIER_LABEL" },
          { name: candidatesName }
        ),
        required: true,
      },
    ];
  }, [candidatesName]);

  const placementMappingOptions = useMemo(() => {
    return [
      {
        value: PLACEMENT_IDENTIFIER,
        text: intl.formatMessage(
          { id: "UNIQUE_IDENTIFIER_LABEL" },
          { name: placementsName }
        ),
        required: true,
      },
      {
        value: CAPACITY,
        text: "Capacity",
      },
      {
        value: PIE_CHART,
        text: "Show Distribution",
      },
    ];
  }, [placementsName]);

  const preferenceMappingOptions = useMemo(() => {
    return [
      {
        value: CANDIDATE_IDENTIFIER,
        text: intl.formatMessage(
          { id: "UNIQUE_IDENTIFIER_LABEL" },
          { name: candidatesName }
        ),
        required: true,
      },
      {
        value: CANDIDATE_PREFERENCE,
        text: intl.formatMessage(
          { id: "PREFERENCE_LABEL" },
          { name: candidatesName }
        ),
        required: true,
      },
      {
        value: PLACEMENT_IDENTIFIER,
        text: intl.formatMessage(
          { id: "UNIQUE_IDENTIFIER_LABEL" },
          { name: placementsName }
        ),
        required: true,
      },
      {
        value: PLACEMENT_PREFERENCE,
        text: intl.formatMessage(
          { id: "PREFERENCE_LABEL" },
          { name: placementsName }
        ),
        required: true,
      },
    ];
  }, [candidatesName, placementsName]);

  return {
    candidateMappingOptions,
    placementMappingOptions,
    preferenceMappingOptions,
  };
};
